import { Component, OnInit, ViewChild } from '@angular/core';

import { environment } from '../../../environments/environment';

import { AuthService } from '@services/auth.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/services/support/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@services/support/loader.service';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { Language } from '@models/Language';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // Login form object
  @ViewChild('loginForm', { static: true }) form: NgForm;

  // Login form binded data
  loginUser = {
    account: '',
    username: '',
    password: ''
  }

  // Booleans for enable and disable login button
  isLoginProgress: boolean = false;
  isFormValid: boolean = false;

  authSubscription: Subscription = null;

  accountNameRemeber: boolean = false;

  verification = {
    open: false,
    code: null
  };
  verifyUser: {
    account: string,
    username: string,
    password: string
  };

  currentLang: Language;
  allLangs: Language[] = [];
  showLangs: boolean = false;
  langSub: Subscription = null;
  isAdmin = true

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private multilanguageService: MultilanguageService,
    private loaderService: LoaderService,
    private snackBarService: SnackbarService,
    private translateService: TranslateService
  ) {
    this.route.params.subscribe(params => {
      this.loginUser.account = params['account_name'];
    });
  }

  ngOnInit() {
    this.authSubscription = this.authService.getAuth().subscribe(auth => {
      if (auth) {
        let returnUrl: string = this.route.snapshot.queryParams['returnUrl']
        if (returnUrl) {
            this.router.navigateByUrl(returnUrl)
            return
        }
        this.router.navigate(['/'])
      }
    });
    const savedAccountName = localStorage.getItem('vsightWorkflowAccountName');
    if (savedAccountName) {
      this.accountNameRemeber = true;
      this.loginUser.account = savedAccountName;
    }

    // Listen for form status
    this.form.statusChanges.subscribe(result => {
      if (result == 'VALID') {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    });

    this.currentLang = this.multilanguageService.currentLang;
    this.allLangs = this.multilanguageService.allLangs;
    this.langSub = this.multilanguageService.onLangChange.subscribe(change => {
      this.currentLang = change.lang;
    });
  }

  onLanguageSelected(lang: Language) {
    this.multilanguageService.setCurrentLanguage(lang.code);
  }

  ngOnDestroy() {
    if (this.authSubscription) { this.authSubscription.unsubscribe() }
    if (this.langSub) { this.langSub.unsubscribe() }
  }

  renderTemplate(templateStr: string, args: any): string {
    templateStr = templateStr.replace(/`/g, '\\`');
    const keys = Object.keys(args);
    const fn = new Function(...keys, 'return `' + templateStr + '`');
    return fn(...keys.map(key => args[key]));
  }

  onLogin(form: NgForm) {
    if (!navigator.onLine) {
      this.translateService.get('LOGIN.NO-INTERNET-ERROR').subscribe(result => {
        this.snackBarService.error(result);
      });
    } else {
      // Start login process if form valid
      // Form always valid. When form not valid -> Login button disabled
      this.isLoginProgress = true;
      this.loaderService.show();

      this.authService.login(form.value.account, form.value.username, form.value.password)
      .then(result => {
        if (result.status && result.status === "verification-sended") {
          this.verifyUser = {
            account: form.value.account,
            username: form.value.username,
            password: form.value.password
          };
          this.verification.open = true;
          this.loaderService.hide();
          this.isLoginProgress = false;
        } else {
          this.authService.loginToFirebase(result.token)
          .then(() => {
            if (this.accountNameRemeber) { localStorage.setItem('vsightWorkflowAccountName', this.loginUser.account) }
            else { localStorage.removeItem('vsightWorkflowAccountName') }
          })
          .finally(() => {
            this.loaderService.hide();
            this.isLoginProgress = false;
          });
        }
      })
      .catch(error => {
        this.loaderService.hide();
        this.isLoginProgress = false;
        this.showLoginError(error);
      });
    }
  }

  onVerify(verificationCode: string) {
    this.loaderService.show();
    this.authService.verifyLoginCode(this.verifyUser.account, this.verifyUser.username, this.verifyUser.password, verificationCode)
    .then(result => {
      return this.authService.loginToFirebase(result.token)
      .then(() => {
        if (this.accountNameRemeber) { localStorage.setItem('vsightWorkflowAccountName', this.loginUser.account) }
        else { localStorage.removeItem('vsightWorkflowAccountName') }
      });
    })
    .catch(error => this.showLoginError(error, true))
    .finally(() => this.loaderService.hide());
  }

  showLoginError(error: any, verificationError: boolean = false) {
    if (error instanceof HttpErrorResponse) {
      if (error.error === 'wrong-username-password') {
        this.translateService.get('LOGIN.USERNAME-PASSWORD-ERROR').subscribe(result => {
          this.snackBarService.error(result);
        });
      } else if (error.error === 'account-disabled') {
        this.translateService.get('LOGIN.ACCOUNT-DISABLED', environment.design).subscribe(result => {
          this.snackBarService.error(result);
        });
        if (verificationError) { this.verification.open = false }
      } else if (error.error === 'no-workflow-license') {
        this.translateService.get('LOGIN.ACCOUNT-DISABLED', environment.design).subscribe(result => {
          this.snackBarService.error(result);
        });
        if (verificationError) { this.verification.open = false }
      } else if (error.error === 'invalid-verification-code') {
        this.translateService.get('LOGIN.INVALID-VERIFICATION-CODE').subscribe(result => {
          this.snackBarService.error(result);
        });
      } else {
        this.translateService.get('LOGIN.UNKNOWN-ERROR').subscribe(result => {
          this.snackBarService.error(result);
        });
      }
    } else if (error instanceof Error && error.message === 'wrong-username-password') {
      this.translateService.get('LOGIN.USERNAME-PASSWORD-ERROR').subscribe(result => {
        this.snackBarService.error(result);
      });
    } else {
      this.translateService.get('LOGIN.UNKNOWN-ERROR').subscribe(result => {
        this.snackBarService.error(result);
      });
    }
  }

  // onTermsofService() {
  //   let url = window.location.origin+"/terms";

  //   const termsUrls = environment.design['termsUrls'];
  //   const defaultTermsLang = environment.design['defaultTermsLang'];
  //   if (termsUrls) {
  //     // TODO when multilanguage added, get current lang code instead of en
  //     // privacyUrls[this.multilanguageService.currentLang.code]
  //     if (termsUrls['en']) {
  //       url = termsUrls['en'];
  //     } else if (defaultTermsLang && termsUrls[defaultTermsLang]) {
  //       url = termsUrls[defaultTermsLang];
  //     }
  //   }
  //   window.open(url);
  // }

  // onPrivacyPolicy() {
  //   let url = window.location.origin+"/privacy";

  //   const privacyUrls = environment.design['privacyUrls'];
  //   const defaultPrivacyLang = environment.design['defaultPrivacyLang'];
  //   if (privacyUrls) {
  //     // TODO when multilanguage added, get current lang code instead of en
  //     // privacyUrls[this.multilanguageService.currentLang.code]
  //     if (privacyUrls['en']) {
  //       url = privacyUrls['en'];
  //     } else if (defaultPrivacyLang && privacyUrls[defaultPrivacyLang]) {
  //       url = privacyUrls[defaultPrivacyLang];
  //     }
  //   }
  //   window.open(url);
  // }
}
