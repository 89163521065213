import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DetailPageService } from '@services/detail-page.service';
import { LoaderService } from '@services/support/loader.service';
import { NavigationService } from '@services/support/navigation.service';
import { UserService } from '@services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { MustMatch } from 'src/app/validators/must-match.validator';
import { PasswordRequirements } from 'src/app/validators/password-requirements.validator';
import { UniqueUsername } from 'src/app/validators/unique-username.validator';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { QRCodeComponent } from 'angularx-qrcode';

import { User } from '../../../../models/User';
import { SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@services/support/snackbar.service';
import { AuthService } from '@services/auth.service';
import { AppService } from '@services/support/app.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {

  @ViewChild('qr', { static: false }) qrContainer: QRCodeComponent;

  @Input() user?
  @Input() detailMode
  @Input() workflowUserCount
  @Input() workflowUserLimit

  passwordValueSub: Subscription = null;
  
  requirements = {
    minLength: 6,
    uppercase: false,
    lowercase: false,
    number: false,
    specialCharacter: false
  }
  AZContains: boolean = false;
  azContains: boolean = false;
  numberContains: boolean = false;
  specialCharacterContains: boolean = false;

  userDetailForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9ıİğĞüÜşŞöÖçÇ][a-zA-Z0-9ıİğĞüÜşŞöÖçÇ\\-._ ]{0,59}$")]),
    username: new UntypedFormControl('', {
      validators: [Validators.required, Validators.pattern("^[a-zA-Z0-9_-]{1,30}$")],
      asyncValidators: [UniqueUsername.createValidator(this.userService)],
      updateOn: 'change'
    }),
    email: new UntypedFormControl('', [Validators.required, Validators.pattern("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,})+$")]),
    workflowUser: new UntypedFormControl(),
    userWorkflows: new UntypedFormControl(),
    password: new UntypedFormControl('', {
      validators: [Validators.required],
      asyncValidators: [PasswordRequirements.createValidator(this.userService)],
      updateOn: 'change'
    }),
    confirmPassword: new UntypedFormControl('', Validators.required),
    editorAccess: new UntypedFormControl(false)
  }, {
    validators: MustMatch('password', 'confirmPassword')
  })

  userData = {
    name: '',
    username: '',
    email: '',
    editorAccess: false
  };

  changes = {
    uid: '',
    name: '',
    properties: {},
    deleted_rooms: [],
    inserted_rooms: []
  }

  userDetailFormSub: Subscription = null;
  formPristine: boolean = true;
  passwordChangeInvalid: boolean = false;

  sidebarSub: Subscription;
  sidebarClosed: boolean = true;

  qrSub: Subscription = null;
  isQrAvailable: boolean = false;
  qrData: string = "";
  qrCodeDownloadLink: SafeUrl = "";

  userRoles = ['coadmin', 'user']
  selectedRole = 'user'

  currentUser: User

  constructor(
    private userService: UserService,
    private detailPageService: DetailPageService,
    private loaderService: LoaderService,
    private modalService: BsModalService,
    private navigationService: NavigationService,
    private translateService: TranslateService,
    private snackBarService: SnackbarService,
    private authService: AuthService,
    private appService: AppService
    ) { }

  get form() {
    return this.userDetailForm.controls;
  }
       
  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.userService.getAllUsernames()
    if (this.user){
      this.userData = {
        name: this.user.name,
        username: this.user.auth.username,
        email: this.user.email,
        editorAccess: this.user.editorAccess ? true : false
      }
      this.userDetailForm.patchValue(this.userData);
      this.selectedRole = this.user?.role ? this.user?.role : this.selectedRole
    }

    // if(this.user.role === 'admin' || this.user.role === 'coadmin') {
    //   this.userDetailForm.controls['editorAccess'].patchValue(true)
    //   this.userDetailForm.controls['editorAccess'].disable()
    // }else{
    //   this.userDetailForm.controls['editorAccess'].enable()
    // }

    if (this.detailMode === 'edit') {
      this.userDetailFormSub = this.userDetailForm.valueChanges.subscribe(f => {
        this.changes['uid'] = this.user.id;
        this.changes.name = f.name !== this.userData.name ? f.name : this.userData.name;
        if (f.name !== this.userData.name) { this.changes.properties['name'] = f.name}
        if (f.username !== this.userData.username) { this.changes.properties['username'] = f.username}
        if (f.email !== this.userData.email) { this.changes.properties['email'] = f.email}
        if (f['editorAccess'] !== this.userData.editorAccess) { this.changes.properties['editorAccess'] = f.editorAccess}
        this.formPristine = (f.name === this.userData.name && f.username === this.userData.username && f.email === this.userData.email && f.editorAccess === this.userData.editorAccess) ? true : false;
      })
   
      if(this.user.auth.qr) {
        this.isQrAvailable = true;
        this.qrData = this.user.auth.account_name + "!!" + this.user.auth.username + '!!' + this.user.auth.qr + '!!v2';
      }else{
        this.isQrAvailable = false;
        this.qrData = null;
      }
    }

    this.passwordValueSub = this.userDetailForm.get('password').valueChanges.subscribe(value => {
      this.testPasswordRequirements(value);
    });
    this.sidebarSub = this.navigationService.sidebarState.subscribe(state => {
      this.sidebarClosed = state;
    });
    this.requirements = this.userService.passwordRequirements;
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  testPasswordRequirements(password: string){
    this.AZContains = RegExp('.*[A-Z].*').test(password);
    this.azContains = RegExp('.*[a-z].*').test(password);
    this.numberContains = RegExp('.*[0-9].*').test(password);
    this.specialCharacterContains = RegExp('.*[-*/?#&()@%+\'!$^:,~_.½£₺é;{}ß>|æ].*').test(password);
  }

  onBackButton(){
    this.detailPageService.removeComponent();
  }

  onUserSubmitForm(){
    const newUser = {
      name: this.userDetailForm.get('name').value,
      role: this.selectedRole,
      allow_archiving: false,
      allow_join_link: false,
      allow_calling: false,
      allow_seeing_all_users: false,
      call_archive_perm_needed: false,
      username: this.userDetailForm.get('username').value,
      email: this.userDetailForm.get('email').value,
      rooms: [],
      password: this.userDetailForm.get('password').value,
      editorAccess: this.userDetailForm.get('editorAccess').value
    }
    this.createUser(newUser);
  }

  createUser(newUser: User) {
    if(this.workflowUserCount == this.workflowUserLimit && this.workflowUserLimit !== 0) {
      const initialState= {
        textMessage : this.translateService.instant('MAIN.CONTENT.USER-DETAIL.LIMIT-WARNING'),
        confirmButtonText : this.translateService.instant('MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.OK'),
        confirmButtonStyle : 'primary'
      };
      let alertmodalRef = this.modalService.show(AlertModalComponent, {
        initialState,
        backdrop: 'static',
        class: 'modal-dialog-centered',
        animated: false
      });
      alertmodalRef.content.onClose.subscribe(isConfirm => {
        if(isConfirm) {
          this.saveUser(newUser,false)
        }
      })
    }else{
      this.saveUser(newUser,true)
    }
  }

  saveUser(newUser,limitError) {
    this.userDetailForm.disable();
      this.loaderService.show();
      return this.userService.createUser(newUser)
      .then(result => {
        if(!result.workflowUser && limitError) {
          this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.USER-DETAIL.CREATED-SUCCESSFULLY'))
        }
        this.detailPageService.removeComponent();
      })
      .catch(error => {
        this.snackBarService.error(this.translateService.instant('ERRORS.ERROR'))
        this.userDetailForm.enable();
      })
      .finally(() => {
        this.loaderService.hide();
      })
  }

  onSaveChanges() {
    this.loaderService.show();
    return this.userService.updateUser(this.changes)
    .then(() => {
      if(this.user.auth?.username == this.currentUser.auth?.username && this.changes.properties?.['editorAccess'] === false) {
        this.appService.isEditorModeSource.next(false)
      }
      this.user.name = this.changes.properties['name'] ? this.changes.properties['name'] : this.user.name;
      this.user.username = this.changes.properties['username'] ? this.changes.properties['username'] : this.user.username;
      this.user.email = this.changes.properties['email'] ? this.changes.properties['email'] : this.user.email;

      /*this.detailPageService.removeComponent()
      const [instance, onClose] = this.detailPageService.loadComponent(UserDetailComponent);
      instance.user = this.user;
      instance.detailMode = 'edit';*/
    })
    .catch(error => {
      if(error.error) {
        this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
      }
    })
    .finally(() => {
      this.loaderService.hide();
    })
  }

  onPasswordChange() {
    this.userDetailForm.disable();
    this.loaderService.show();
    return this.userService.changeUserPassword(this.user.id, this.user.auth.username, this.userDetailForm.get('password').value)
    .then(() => {
      // const [instance, OnClose] = this.detailPageService.loadComponent(UserDetailComponent);
      // instance.user = this.user;
      // instance.detailMode = 'edit';
    })
    .catch(error => {
      this.userDetailForm.enable();
    })
    .finally(() => {
      this.loaderService.hide();
    })
  }

  deleteUser(userId: string) {
    const initialState = {
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.DELETE-USER'),
      textMessage : this.translateService.instant('MAIN.CONTENT.USER-DETAIL.DELETE-USER-OR-NOT'),
      confirmButtonText : this.translateService.instant('MAIN.CONTENT.USER-DETAIL.DELETE')
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    alertmodalRef.content.onClose.subscribe(res => {
      if (res) {
        this.loaderService.show();
        this.userService.deleteUser(userId).finally(() => {
          this.loaderService.hide();
          this.detailPageService.removeComponent();
        });
      } else {
        alertmodalRef.hide();
      }
    })
  }

  onGenerateQR() {
    this.loaderService.show();
    this.userService.generateQr(this.user.auth.username)
    .then(() => {
      this.isQrAvailable = true;
    })
    .catch(error => {
      if(error.error) {
        this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
      }
    })
    .finally(() => {
      this.loaderService.hide();
    })
  }

  onRegenerateQR() {
    this.loaderService.show();
    this.userService.generateQr(this.user.auth.username)
    .then(() => {

    })
    .catch(error => {
      if(error.error) {
        this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
      }
    })
    .finally(() => {
      this.loaderService.hide();
    })
  }

  onDeleteQR() {
    const initialState = {
      headerTitle: ' ',
      textMessage : this.translateService.instant('MAIN.CONTENT.USER-DETAIL.DELETE-QR-OR-NOT'),
      confirmButtonText : this.translateService.instant('MAIN.CONTENT.USER-DETAIL.DELETE'),
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    alertmodalRef.content.onClose.subscribe(res => {
      if (res) {
        this.isQrAvailable = false;
        this.userService.removeQR(this.user.auth.username)
        .catch(error => {
          if(error.error) {
            this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
          }
        })
      } else {
        alertmodalRef.hide();
      }
    })
  }

  selectRole(role) {
    
    this.selectedRole = role
    if(role == 'coadmin') {
      this.userDetailForm.controls['editorAccess'].patchValue(true)
      this.userDetailForm.controls['editorAccess'].disable()
    }else{
      this.userDetailForm.controls['editorAccess'].enable()
    }
  }

  ngOnDestroy() {
    if (this.passwordValueSub) { this.passwordValueSub.unsubscribe() }
    if (this.sidebarSub) { this.sidebarSub.unsubscribe() }
    if (this.qrSub) { this.qrSub.unsubscribe() }
    if (this.userDetailFormSub) { this.userDetailFormSub.unsubscribe() }
  }
}
