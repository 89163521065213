export const TYPEOPTIONS = {
    number: 'Number',
    input: 'Text',
    radio: 'Radio button',
    multicheckbox: 'Checkboxes',
    datepicker: 'Date',
    time: 'Time',
    fileUpload: 'File Upload',
    textarea: 'Text',
    captureImage: 'Capture Image',
    captureVideo: 'Capture Video',
    ppe: 'PPE',
    pdp: 'Problem Detection with Photo',
    pdt: 'Problem Detection with Text',
    qr: 'QR'
  };

export const GENERIC = {
    number: [
      { value: 'equals', title: 'Equals', img: '' },
      { value: 'notEquals', title: 'Not Equals', img: '' },
      { value: 'greaterThan', title: 'Greater than', img: '' },
      { value: 'lessThan', title: 'Less than', img: '' },
      { value: 'greaterOrEqual', title: 'Greater or Equal', img: '' },
      { value: 'lessOrEqual', title: 'Less or Equal', img: '' },
      { value: 'isEmpty', title: 'Is Empty', img: '' },
      { value: 'isNotEmpty', title: 'Is not empty', img: '' },
    ],
    input: [
      { value: 'is', title: 'Is' },
      { value: 'isNot', title: 'Is not' },
      { value: 'contains', title: 'Contains' },
      { value: 'notContain', title: 'Does not contain' },
      { value: 'startsWith', title: 'Starts with' },
      { value: 'endsWith', title: 'Ends with' },
      { value: 'isEmpty', title: 'Is empty' },
      { value: 'isNotEmpty', title: 'Is not empty' },
    ],
    radio: [
      { value: 'is', title: 'Is' },
      { value: 'isNot', title: 'Is not' },
      { value: 'isEmpty', title: 'Is empty' },
      { value: 'isNotEmpty', title: 'Is not empty' },
    ],
    multicheckbox: [
      { value: 'contains', title: 'Contains' },
      { value: 'notContain', title: 'Does not contain' },
      { value: 'isEmpty', title: 'Is empty' },
      { value: 'isNotEmpty', title: 'Is not empty' },
    ],
    datepicker: [
      { value: 'is', title: 'Is' },
      { value: 'isNot', title: 'Is not' },
      { value: 'isBefore', title: 'Is before' },
      { value: 'isAfter', title: 'Is after' },
      { value: 'isBetween', title: 'Is between' },
      { value: 'isNotBetween', title: 'Is not between' },
      { value: 'isEmpty', title: 'Is empty' },
      { value: 'isNotEmpty', title: 'Is not empty' },
    ],
    time: [
      { value: 'is', title: 'Is' },
      { value: 'isNot', title: 'Is not' },
      { value: 'isBefore', title: 'Is before' },
      { value: 'isAfter', title: 'Is after' },
      { value: 'isEmpty', title: 'Is empty' },
      { value: 'isNotEmpty', title: 'Is not empty' },
    ],
    captureImage: [
      { value: 'isEmpty', title: 'Is empty' },
      { value: 'isNotEmpty', title: 'Is not empty' },
    ],
    captureVideo: [
      { value: 'isEmpty', title: 'Is empty' },
      { value: 'isNotEmpty', title: 'Is not empty' },
    ],
    qr: [
      { value: 'isEmpty', title: 'Is empty' },
      { value: 'isNotEmpty', title: 'Is not empty' },
      // qr value eşleşmesi
    ],
    ppe: [
      { value: 'isDetected', title: 'Is detected' },
      { value: 'isNotDetected', title: 'Is not detected' },
      // ppe value eşleşmesi mobil tarafta detected neye göre
    ],
    pdp: [
      { value: 'is', title: 'Is' },
      { value: 'isNot', title: 'Is not' },
      { value: 'contains', title: 'Contains' },
      { value: 'notContain', title: 'Does not contain' },
      { value: 'startsWith', title: 'Starts with' },
      { value: 'endsWith', title: 'Ends with' },
      { value: 'isEmpty', title: 'Is empty' },
      { value: 'isNotEmpty', title: 'Is not empty' },
    ],
    pdt: [
      { value: 'is', title: 'Is' },
      { value: 'isNot', title: 'Is not' },
      { value: 'contains', title: 'Contains' },
      { value: 'notContain', title: 'Does not contain' },
      { value: 'startsWith', title: 'Starts with' },
      { value: 'endsWith', title: 'Ends with' },
      { value: 'isEmpty', title: 'Is empty' },
      { value: 'isNotEmpty', title: 'Is not empty' },
    ],
  };