import { Injectable } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { CollaborationData } from '@models/CollaborationData';
import { first } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '@services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CollaborationService {

  STATE_WAITING = "waiting";
  STATE_SUCCEEDED = "succeeded";
  STATE_FAILED = "failed";

  currentCollaborationPath: string = null;

  constructor(
    private authService: AuthService,
    private afdb: AngularFireDatabase
  ) { }

  setCurrentCollaboration(collaboration: any, collaborationId: string) {
    this.currentCollaborationPath = `accounts/${this.authService.currentUser.account_id}/workflow_collaborations/${collaborationId}`;
    return this.afdb.object(`accounts/${this.authService.currentUser.account_id}/workflow_collaborations/${collaborationId}`).set(collaboration);
  }

  getCurrentCollaboration(collaborationId: string = null) {
    this.currentCollaborationPath = `accounts/${this.authService.currentUser.account_id}/workflow_collaborations/${collaborationId}`;
    return this.afdb.object<CollaborationData>(this.currentCollaborationPath).valueChanges();
  }

  removeCurrentCollaboration(collaborationId: string) {
    return this.afdb.object(`accounts/${this.authService.currentUser.account_id}/workflow_collaborations/${collaborationId}`).remove();
  }

  createCollaboration(key: string, name: string, url: string) {
    const uid = this.authService.currentUser.id;
    const uname = this.authService.currentUser.name;
    const collaboration = {
      collaboration_data: {
        type: "image",
        key: key,
        name: name,
        annotating: true,
        status: {
          [uid]: {
            state: "waiting",
            name: uname
          },
          ["dummy"]: {
            state: "succeeded",
            name: ""
          }
        },
        url: url
      },
      controls: {
        zoom_ratio: 1.0,
        pdf_scroll_ratio: 0,
        pdf_current_page: 1,
        polar_angle: 0,
        azimuthal_angle: 0,
        final_polar_angle: 0,
        final_azimuthal_angle: 0,
        hide:true,
        close:false,
        page_ratio: 0,
        x: 0.5,
        y: 0.5,
        z: 1,
        refresh: "no",
        revert: "no"
      }
    };
    return collaboration;
  }

  getCollaborationData(id: string = null): Observable<CollaborationData> {
    const path = this.currentCollaborationPath + '/collaboration_data';
    return this.afdb.object<CollaborationData>(path).valueChanges();
  }

  toggleAnnotate(annotating: boolean){
    this.afdb.object<CollaborationData>(this.currentCollaborationPath + '/collaboration_data').update({annotating: annotating});
  }

  // methods for remote synchronization
  setCollaborationLoaded() { return new Promise<void>(resolve => resolve()) }
  closeCollaboration() { return new Promise<boolean>(resolve => resolve(true)) }
}