<div class="dashboard-wrapper pt-5">
    <div class="v-card">
        <div class="card list-header flex-row justify-content-between align-items-center pe-4 ps-4">
            <div class="header fs-16 font-weight-600  pt-1">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.TITLE' | translate }}</div>
            <div><button class="v-btn btn btn-primary fs-12 font-weight-600 text-white add-button" (click)="onAddWebhook()">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.NEW_WEBHOOK' | translate }}</button></div>
        </div>
        <div class="card list-area p-4">
            <form #webhooksForm="ngForm" autocomplete="off">
                <div class="form-row px-4">
                    <div *ngIf="webhooks.length === 0">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.NO_WEBHOOK' | translate }}</div>
                    <div class="form-group d-flex align-items-center w-100 pl-0" *ngIf="webhooks.length > 0">
                        <div class="font-weight-bold me-2" style="width: 240px;">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.EVENT' | translate }}</div>
                        <div class="font-weight-bold" style="flex-grow: 1;">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.URL' | translate }}</div>
                    </div>
                    <div *ngFor="let webhook of webhooks" class="form-group d-flex align-items-center mt-2 w-100 pl-0">
                        <input class="form-control fs-14 me-2" style="width: 240px; min-width: 240px;" [value]="webhook.event" [disabled]="true" type="text">
                        <input class="form-control fs-14" style="flex-grow: 1;" [value]="webhook.url" [disabled]="true" type="text">
                        <button class="btn btn-danger btn-sm h-100 ms-2 px-3 remove-button" (click)="onRemoveWebhook(webhook)">{{ 'MAIN.CONTENT.SETTINGS.WEBHOOKS.REMOVE' | translate }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>