import { Component, OnInit,Input, Renderer2, ViewChild, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@models/User';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { AppService } from '@services/support/app.service';
import { LoaderService } from '@services/support/loader.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertModalComponent } from '../../modals/alert-modal/alert-modal.component';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() isAdmin
  @Input() isEditorMode
  @Input() addOns

  userSub: Subscription;
  user: User
  version
  currentLang
  allLangs = []
  showLangs: boolean = false
  
  @ViewChild('optionsDropdown') optionsDropdown?: any;
  @ViewChild('menu') menu: any;

  subscriptions: Subscription[] = []
  webhooksAvailable: boolean = false
  hasVideoFlow: boolean = false

  constructor(private translateService: TranslateService,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private renderer: Renderer2,
    private appService: AppService,
    private multilanguageService:MultilanguageService,
    private router: Router) {
      this.renderer.listen('window', 'click', (e: Event) => {
        if (e.target !== this.optionsDropdown ?.nativeElement && e.target !== this.menu ?.nativeElement) {
          
        }
      })
     }
     
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.authService.user.subscribe((user) => {
        this.user = user;
      })
    )
    this.authService.getAccountData().then(account => {
      this.webhooksAvailable = account.data.accountData.add_ons?.workflowwebhooks
      this.hasVideoFlow = account.data.accountData.add_ons?.videoflow
    })
    
    this.currentLang = this.multilanguageService.currentLang;
    this.allLangs = this.multilanguageService.allLangs;
  
    this.subscriptions.push(
      this.multilanguageService.onLangChange.subscribe(change => {
        this.currentLang = change.lang;
      })
    );
  
    this.version = environment.version;
  }

  dropdownAction(workflow, event: Event) {
    event.stopPropagation()
    workflow['show'] = !workflow['show']
  }

  onLogout() {
    const initialState= {
      textMessage : this.translateService.instant('MAIN.NAVBAR.LOGOUT.TEXT-MESSAGE.MESSAGE'),
      confirmButtonText : this.translateService.instant('MAIN.NAVBAR.LOGOUT.TEXT-MESSAGE.CONFIRM'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.LOGOUT')
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    })
    alertmodalRef.content.onClose.subscribe((res) => {
      if (res) {
        this.loaderService.show();
        this.authService.logout().finally(() => {
          this.loaderService.hide();
        });
      }else{
        alertmodalRef.hide()
      }
    });
  }

  switchMode(mode) {
    this.appService.isEditorModeSource.next(mode)
    this.router.navigate(['/']);
  }

  onLanguageSelected(lang: any) {
    this.multilanguageService.setCurrentLanguage(lang.code);
  }
}
