<div class="dashboard-wrapper" *ngIf="dataLoaded">
  <div class="v-card" [ngStyle]="{'display': userDetailOpen ? 'none' : 'block'}">
    <ng-container *ngIf="false">
      <div class="d-flex align-items-center me-5">
        <div class="col-md-5 col-sm-12" style="margin-right: 3rem !important; margin-left: 2rem;">
          <p class="fs-32">{{ 'MAIN.CONTENT.USER-LIST.TITLE' | translate }}</p> 
          <div class="fs-16-light">{{ 'MAIN.CONTENT.USER-LIST.SIMPLIFY' | translate }}</div>
          <div>
            <button class="v-btn btn btn-primary m-2 fs-12 text-white create-button" style="margin-left: 0px!important;">
              {{ 'MAIN.CONTENT.USER-LIST.CREATE' | translate }}
            </button>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <img src="assets/img/empty.svg" alt="">
        </div>
      </div>
    </ng-container>
    <div class="card list-header list-header-fixed flex-row justify-content-between align-items-center pe-4 ps-4">
      <div class="header fs-20 font-weight-700 pt-1">{{ 'MAIN.CONTENT.USER-LIST.TITLE' | translate }}</div>
      <div class="button-container">
        <button *ngIf="tabIndex === 0" class="v-btn btn btn-primary fs-15 font-weight-600 text-white create-button" (click)="onCreate()">
          {{ 'MAIN.CONTENT.USER-LIST.CREATE' | translate | titlecase }}
        </button>
        <button *ngIf="tabIndex === 1" class="v-btn btn btn-primary fs-15 font-weight-600 text-white create-button" (click)="onCreate()">
          {{ 'MAIN.CONTENT.USER-LIST.CREATE-TEAM' | translate | titlecase }}
        </button>
      </div>
    </div>

    <div class="card tab-area first-area d-flex flex-row">
      <div class="tab-item left fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 0 }" (click)="onTabClicked(0)">
        <span class="tab-title">
          {{ 'MAIN.CONTENT.USER-LIST.TITLE' | translate }}
        </span>
      </div>
      <!-- <div class="tab-item right fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 1 }" (click)="onTabClicked(1)">
        <span class="tab-title">
          {{ 'MAIN.CONTENT.USER-LIST.TEAMS' | translate }}
        </span>
      </div> -->
    </div>
  </div>

  <div *ngIf="tabIndex === 0">
    <div class="card list-area">
      <div class="card-body pe-4 ps-3 filter-area">
        <div class="d-flex justify-content-between">
          <div class="workflow-users-info">
            <label class="text-dark-grey fs-14 font-weight-400">
              {{ 'MAIN.CONTENT.USER-LIST.LICENSED' | translate }}
              <span class="font-weight-600 fs-14 text-dark-grey">
                {{ workflowUserCount }}/{{ workflowUserLimit == 0 ? ('MAIN.CONTENT.USER-LIST.UNLIMITED' | translate) : workflowUserLimit }}
              </span>
            </label>
          </div>

          <div class="search-box">
            <input type="text" [formControl]="filter" placeholder="{{ 'MAIN.CONTENT.USER-LIST.SEARCH' | translate }}" class="filter-input fs-14">
            <div class="search-icon"><img src="assets/img/search.svg" alt=""></div>
          </div>
        </div>
      </div>
        <div [formGroup]="formGroup" class="d-flex align-items-center justify-content-center">
          <table mat-table [dataSource]="dataSource" matSort matTableResponsive>
            <ng-container matColumnDef="workflowUser">
              <th mat-header-cell *matHeaderCellDef class="col-2 table-fs-14 fs-14 text-dark-grey font-weight-400" style="padding-left: 20px;" mat-sort-header sortActionDescription="Sort by workflowUser">
                {{ 'MAIN.CONTENT.USER-LIST.LIST.WORKFLOW-USER' | translate }}
              </th>
              <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS'" *matCellDef="let element" class="col-2 d-flex align-items-start justify-content-center flex-column">
                <div class="d-flex align-items-center justify-content-center" style="padding-left: 33px !important;">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" [formControlName]="element.id" />
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th class="col-2 table-fs-14 fs-14 text-dark-grey font-weight-400 ps-2"  mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                {{ 'MAIN.CONTENT.USER-LIST.LIST.NAME' | translate }}
              </th>
              <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.DURATION'" *matCellDef="let element" class="col-2 fs-15 font-weight-700  ps-2 d-flex align-items-center">
                <div>{{ element.name }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef class="col-4 table-fs-14 fs-14 text-dark-grey font-weight-400">
                {{ 'MAIN.CONTENT.USER-LIST.LIST.USERNAME' | translate }}
              </th>
              <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS'" *matCellDef="let element" class="col-4 fs-14 font-weight-400 d-flex align-items-start justify-content-center flex-column">
                <div>{{ element.ad_user ? element.user_principal_name : element.auth.username }}</div>
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="teams">
              <th mat-header-cell *matHeaderCellDef class="col-2 table-fs-14 fs-14 text-dark-grey font-weight-400">
                {{ 'MAIN.CONTENT.USER-LIST.TEAM' | translate }}
              </th>
              <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS'" *matCellDef="let element" class="col-2 fs-12 font-weight-400 d-flex align-items-start justify-content-center flex-column">
                <div class="team-tags ps-0">
                  <span class="me-2">Marketing</span>
                </div>
              </td>
            </ng-container> -->

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="col-4 table-fs-14 text-center"></th>
              <td mat-cell *matCellDef="let element" class="col-4 d-flex justify-content-end align-items-center" style="padding-right: 30px;">
                <app-dropdown title="{{ 'MAIN.CONTENT.USER-LIST.ACTIONS.OPTIONS' | translate }}" style="width: 120px;">
                  <li *ngIf="!element.ad_user" role="menuitem">
                    <a class="dropdown-item edit-button" (click)="onEdit(element)">
                      {{ 'MAIN.CONTENT.USER-LIST.ACTIONS.EDIT' | translate }}
                    </a>
                  </li>
                  <li *ngIf="!(element.ad_user || element.auth.username === currentUser.auth.username || element.role === 'admin' || element.role === 'coadmin')" role="menuitem">
                    <a class="dropdown-item delete-button" (click)="onDelete(element.id)">
                      {{ 'MAIN.CONTENT.USER-LIST.ACTIONS.DELETE' | translate }}
                    </a>
                  </li>
                </app-dropdown>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
    </div>
  </div>

  <div *ngIf="tabIndex === 1">
    <div class="card list-area">
      <div class="card-body pe-0 filter-area">
        <div class="d-flex justify-content-end">
             <!--
          <span class="me-2 fs-14 text-dark-grey font-weight-600 ms-1">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.FILTER-BY' | translate }}</span>
          <div class="btn-group dropdown-wrapper-list filter-input ps-0 pe-0" dropdown style="width: 240px; border: none;">
              <button type="button" class="btn btn-primary action-dropdown p-0 text-start ps-3 text-dark-grey fs-13" (click)="toggle()" #toggleButton dropdownToggle data-bs-auto-close="inside">
                  {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.SELECT-LABEL' | translate }}
              </button>
              <button id="button-split" type="button" class="btn dropdown-toggle dropdown-toggle-split" (click)="$event.stopPropagation(); toggleButton.click()"
                  aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false">
              </button>
              <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" onclick="event.stopPropagation()" style="max-height: 240px; 
              overflow-y: scroll;" [formGroup]="labelGroup" aria-labelledby="dropdownMenuClickableInside">
                  <div class="search-box p-2">
                      <input type="text" class="form-control fs-14" [formControl]="labelSearchControl" style="height: 38px;" placeholder="{{ 'MAIN.CONTENT.WORKFLOW.LANGUAGE.SEARCH' | translate }}" inputSelectOnFocus [inputSelectOnCreate]="true">
                      <div class="search-icon me-2"> <img src="assets/img/search-copy.svg" alt=""></div>
                  </div>
                  <div *ngFor="let label of filteredLabelList; let index = index" class="ps-2">
                      <div class="custom-control custom-checkbox custom-checkbox-green">
                          <input
                              type="checkbox"
                              class="form-check-input ms-0"
                              [id]="index"
                              (click) = selectLabels(label)
                              [formControlName]="label"
                          />
                          <label class="custom-control-label label-form fs-14" [for]="index">
                              {{ label }}
                          </label>
                      </div>
                  </div>
              </ul>
          </div>
          -->
          <div class="search-box">
            <input type="text" [formControl]="filter" placeholder="{{ 'MAIN.CONTENT.USER-LIST.SEARCH' | translate }}" class="filter-input fs-14">
            <div class="search-icon"><img src="assets/img/search.svg" alt=""></div>
          </div>
        </div>
      </div>
        <div [formGroup]="formGroup"  class="d-flex align-items-center justify-content-center ps-3">
          <table mat-table [dataSource]="dataSource" matSort matTableResponsive>
            <ng-container matColumnDef="teamname">
              <th class="col-2 table-fs-14 fs-14 text-dark-grey font-weight-400 ps-0" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                {{ 'MAIN.CONTENT.USER-LIST.LIST.NAME' | translate }}
              </th>
              <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.DURATION'" *matCellDef="let element" class="col-2 fs-15 font-weight-700 ps-0 d-flex align-items-center">
                <div>Marketing</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="members">
              <th mat-header-cell *matHeaderCellDef class="col-2 table-fs-14 fs-14 text-dark-grey font-weight-400" mat-header-cell mat-sort-header>
                Members
              </th>
              <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS'" *matCellDef="let element" class="col-2 fs-14 font-weight-400 d-flex align-items-start justify-content-center flex-column">
                <div>10 Members</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="col-8 table-fs-14 text-center"></th>
              <td mat-cell *matCellDef="let element" class="col-8 d-flex justify-content-end align-items-center" style="padding-right: 30px;">
                <app-dropdown title="{{ 'MAIN.CONTENT.USER-LIST.ACTIONS.OPTIONS' | translate }}" style="width: 120px;">
                  <li *ngIf="!element.ad_user" role="menuitem">
                    <a class="dropdown-item edit-button" (click)="onEdit(element)">
                      {{ 'MAIN.CONTENT.USER-LIST.ACTIONS.EDIT' | translate }}
                    </a>
                  </li>
                  <li *ngIf="!(element.ad_user || element.auth.username === currentUser.auth.username || element.role === 'admin' || element.role === 'coadmin')" role="menuitem">
                    <a class="dropdown-item delete-button" (click)="onDelete(element.id)">
                      {{ 'MAIN.CONTENT.USER-LIST.ACTIONS.DELETE' | translate }}
                    </a>
                  </li>
                </app-dropdown>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
    </div>
  </div>

  <ng-container *ngIf="showCountPanel && (newDeactivatedWorkflows !== 0 || (newDeactivatedWorkflows - newActivatedWorkflows !== 0))">
    <div class="card user-information">
      <div class="card-body info">
        <div class="d-flex align-items-center" style="position: absolute;right: 0;bottom: 0;left: 0;top: 0;z-index: 3;">
          <div class="d-flex flex-column align-items-center">
            <label class="fs-14 font-weight-500 ms-4 me-4 text-white">
              {{ newActivatedWorkflows }} user activated, {{ newDeactivatedWorkflows}} {{'MAIN.CONTENT.USER-LIST.DEACTIVATE.DEACTIVATED' | translate}}
            </label>
            <p class="fs-14 font-weight-500 mt-0 mb-0" style="color: #dc3545;" *ngIf="workflowUserLimit !== 0 ? workflowUserCount + total > workflowUserLimit : false">
              ! Limit exceeded
            </p>
          </div>
          <button class="v-btn btn btn-primary me-1 bg-white" style="color: #003DA6;" (click)="saveWorkflowUsers()" [disabled]="workflowUserLimit !== 0 ? workflowUserCount + total > workflowUserLimit : false">
            {{ 'MAIN.CONTENT.USER-LIST.DEACTIVATE.SAVE' | translate }}
          </button>
          <button class="v-btn btn btn-primary-outline" style="color: white;border-color: white;" (click)="cancel()">
            {{ 'MAIN.CONTENT.USER-LIST.DEACTIVATE.CANCEL' | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #empty>
  <div class="d-flex">
    <div class="col-6"></div>
    <div class="col-6">
      <img src="assets/img/empty.svg" alt="">
    </div>
  </div>
</ng-template>
