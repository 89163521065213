<div class="dashboard-wrapper" *ngIf="dataLoaded && isEditorMode" [ngClass]="{'bg-white d-flex align-items-center': !workflows.length && dataLoaded}">
    <div [ngClass]="[status!=='modal' ? 'v-card' : 'v-card-modal']">
        <ng-container *ngIf="!workflows.length && dataLoaded">
            <div class="d-flex align-items-center me-5">
                <div class="col-md-5 col-sm-12" style="margin-right: 3rem !important; margin-left: 2rem;">
                    <p class="fs-32">{{ 'MAIN.CONTENT.DASHBOARD.NO-WORKFLOW.TITLE' | translate }}</p>
                    <div class="fs-16-light">{{ 'MAIN.CONTENT.DASHBOARD.NO-WORKFLOW.TEXT' | translate }}</div>
                    <div>
                        <button class="v-btn btn btn-primary m-2 fs-12 font-weight-600 text-white" style="margin-left: 0px!important;" (click)="createWorkflow()">{{ 'MAIN.CONTENT.DASHBOARD.NO-WORKFLOW.CREATE' | translate }}
                        </button>
                    </div>
                </div>
                <div class="col-md-7 col-sm-12">
                    <img src="assets/img/empty.svg" alt="">
                </div>
            </div>
        </ng-container>

        <div class="loading-process" *ngIf="showLoadingAI">
            <ng-lottie [options]="optionsi" (animationCreated)="animationCreated($event)" ></ng-lottie>
            <div class="fs-14 text-white ai-text">{{ 'MAIN.CONTENT.DASHBOARD.AI-IN-PROGRESS' | translate }}</div>
        </div>

        <div class="workflow-background" *ngIf="showLoadingAI"></div>
        
        <div class="card list-header list-header-fixed flex-row justify-content-between align-items-center pe-4 ps-4" *ngIf="status!== 'modal' && workflows.length">
            <div class="d-flex">
                <div class="header fs-20 font-weight-700  pt-1 me-2">{{ 'MAIN.CONTENT.DASHBOARD.TITLE' | translate }}</div>
                <div *ngIf="('electron' | env)">
                    <button (click)="exportPublishedWorkflows()" class="v-btn btn btn-primary fs-12 font-weight-600 text-white" style="border-radius:7px; height: 30px;padding-top:5px">{{ 'MAIN.CONTENT.DASHBOARD.EXPORT-WORKFLOWS' | translate }}</button>
                </div>
            </div>
            
            <div>
                <button class="btn btn-primary fs-15 font-weight-600 text-white create-button me-2 pe-4 ps-4 ai-btn"
                 (click)="createWorkflowWithOpenAI()" *ngIf="isAIAvailable">{{ 'MAIN.CONTENT.DASHBOARD.GENERATE' | translate }} 
                <img src="assets/img/ai_icon.svg" alt="" class="ms-1" height="20px"></button>
                <button class="v-btn btn btn-primary fs-15 font-weight-600 text-white create-button" (click)="createWorkflow()">{{ 'MAIN.CONTENT.DASHBOARD.CREATE' | translate }}</button>
            </div>

        </div>
        <div class="card tab-area d-flex flex-row first-area" *ngIf="dataLoaded && workflows.length>0">
            <div class="tab-item left fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 0 }" (click)="onTabClicked(0)">
                <span class="tab-title">
                    {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ALL-WORKFLOWS' | translate }}
                </span>
            </div>
            <div class="tab-item right fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 1 }" (click)="onTabClicked(1)">
                <span class="tab-title">
                    {{ 'MAIN.CONTENT.DASHBOARD.FOLDER.FOLDERS' | translate }}
                </span>
            </div>
        </div>
        <!-- <div class="card-body pe-4 ps-4 filter-area" *ngIf="dataLoaded && workflows.length>0">
            <div class="d-flex justify-content-between" style="padding-top: 2px;">
                <div class="d-flex align-items-center">
                    <span class="me-2 fs-14">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.FILTER-BY' | translate }}</span>
                    <div class="btn-group dropdown-wrapper-list filter-input ps-0 pe-0" dropdown style="width: 240px; border: none;">
                        <button type="button" class="btn btn-primary action-dropdown p-0 text-start ps-3" (click)="toggle()" #toggleButton dropdownToggle data-bs-auto-close="inside">
                            {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.SELECT-LABEL' | translate }}
                        </button>
                        <button id="button-split" type="button"  class="btn dropdown-toggle dropdown-toggle-split"  (click)="$event.stopPropagation(); toggleButton.click()"
                                aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false">
                        </button>
                        <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" onclick="event.stopPropagation()"  style="max-height: 240px; 
                        overflow-y: scroll;" [formGroup]="labelGroup" aria-labelledby="dropdownMenuClickableInside">
                            <div class="search-box p-2">
                                <input  type="text" class="form-control fs-14" [formControl]="labelSearchControl" style="height: 38px;"  placeholder="{{ 'MAIN.CONTENT.WORKFLOW.LANGUAGE.SEARCH' | translate }}" inputSelectOnFocus [inputSelectOnCreate]="true">
                                <div class="search-icon me-2"> <img src="assets/img/search-copy.svg" alt=""></div>
                            </div>
                            <div *ngFor="let label of filteredLabelList; let index = index" class="ps-2">
                                <div class="custom-control custom-checkbox custom-checkbox-green">
                                    <input
                                        type="checkbox"
                                        class="form-check-input ms-0"
                                        [id]="index"
                                        (click) = selectLabels(label)
                                        [formControlName]="label"
                                    />
                                    <label class="custom-control-label label-form fs-14" [for]="index">
                                        {{ label }}
                                    </label>
                                </div>
                            </div>
                        </ul>
                    </div>
                    <div *ngIf="tabIndex == 1 && selectedWorkflowIds?.length" class="d-flex">
                        <label class="fs-13 font-weight-400 me-3 ms-3">
                            {{selectedWorkflowIds.length}} {{ 'MAIN.CONTENT.DASHBOARD.FOLDER.SELECTED' | translate }}</label>
                        <div class="fs-13 font-weight-700 text-blue cursor-pointer" (click)="moveToFolder(selectedWorkflowIds)">
                            <div class="d-flex">
                                <img src="assets/img/move-to-folder.svg" alt="">
                                <span class="ms-2">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.MOVE-TO-FOLDER' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>

              <div class="search-box">
                <input type="text" [formControl]="filter" placeholder="{{ 'MAIN.CONTENT.USER-LIST.SEARCH' | translate }}" class="filter-input fs-14">
                <div class="search-icon"> <img src="assets/img/search.svg" alt=""></div>
              </div>
             
            </div>

            <div class="mt-3 d-flex flex-wrap" *ngIf="filteredLabels?.length">
                <span class="fs-14 me-2">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.SELECTED-LABELS' | translate }} : </span>
                <span class="fs-12 label-span me-2 mb-2" *ngFor="let label of filteredLabels">{{label}} <img (click)="deleteLabel(label)" class="label-close cursor-pointer" src="assets/img/close.svg" alt=""></span>
                <span class="fs-13 font-weight-600 cursor-pointer" [ngClass]="{'disabled': isLoadingFilter}" (click)="clearAll()">Clear all</span>
            </div>
        </div> -->
        <div class="card list-area" *ngIf="dataLoaded && workflows.length" [ngStyle]="{'padding-left': tabIndex==1 ? '0' : ''}">

            <div [ngClass]="{'d-flex' : tabIndex == 1}">
                <div *ngIf="tabIndex == 1" class="folder-area">
                    <app-folders
                        [folders]="folders"
                        (folderDeleted)="folderDelete($event)"
                        (selectedFolderChange)="selectedFolderChanged($event)"
                        (notFolderedSelectedChange)="notFolderedSelectedChange($event)">
                    </app-folders>
                    <!-- <div class="d-flex justify-content-center">
                        <button class="btn btn-primary fs-13 font-weight-600 folder-create-button" (click)="createNewFolder()">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.CREATE-FOLDER' | translate }}</button>
                    </div>
                    <div class="fs-14 font-weight-400 cursor-pointer ps-4 folder-name-wrapper not-foldered-item d-flex align-items-center" [ngClass]="{'selected-folder': !selectedFolder?.name }" (click)="showNotFolderedWorkflows()">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.NOT-FOLDERED' | translate }}</div>
                    <div *ngFor="let folder of folders" class="fs-14 font-weight-400 mt-2 d-flex justify-content-between align-items-center folder-name-wrapper" (click)="selectFolder(folder)" [ngClass]="{'selected-folder': selectedFolder?.name == folder.name}">
                        <div class="d-flex cursor-pointer" >
                            <img src="assets/img/folder-list.svg" alt="">
                            <span class="ms-2 folder-name"  title="{{folder.name}}">{{folder.name}} 
                                <span class="fs-14 text-light-grey">({{folder.workflows?.length}})</span>
                            </span>
                        </div>

                        <div class="dropdown-wrapper" dropdown container="body">
                            <a id="{{folder}}" class="btn btn-default name-wrapper" id="dropdownMenuLink" dropdownToggle aria-controls="dropdown-basic">
                                <img src="assets/img/more.svg" alt="" height="20px">
                            </a>
                            
                            <div #stepListStepOptions class="dropdown-menu" *dropdownMenu role="menu" aria-labelledby="dropdownMenuLink">
                                <div>
                                    <a class="dropdown-item folder-item" (click)="editFolder(folder)">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-LIST.STEP.OPTIONS.RENAME' | translate }}</a>
                                    <a class="dropdown-item folder-item text-red" (click)="deleteFolder(folder)">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.DELETE-FOLDER' | translate }}</a>
                                </div>
                            </div>
                        </div>

                    </div> -->
                </div>
    
                <div [ngClass]="{'w-100' : tabIndex == 1}" style="display: flex;flex-direction: column; text-align: center;">
                    <div class="pe-4 ps-3 filter-area" *ngIf="dataLoaded && workflows.length>0">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex align-items-center">
                                <span class="me-2 fs-14 font-weight-600 ms-1">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.FILTER-BY' | translate }}</span>
                                <div class="btn-group dropdown-wrapper-list filter-input ps-0 pe-0" dropdown style="width: 240px; border: none;">
                                    <button type="button" class="btn btn-primary action-dropdown p-0 text-start ps-3 text-dark-grey fs-13" (click)="toggle()" #toggleButton dropdownToggle data-bs-auto-close="inside">
                                        {{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.SELECT-LABEL' | translate }}
                                    </button>
                                    <button id="button-split" type="button"  class="btn dropdown-toggle dropdown-toggle-split"  (click)="$event.stopPropagation(); toggleButton.click()"
                                            aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false">
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" onclick="event.stopPropagation()"  style="max-height: 240px; 
                                    overflow-y: scroll;" [formGroup]="labelGroup" aria-labelledby="dropdownMenuClickableInside">
                                        <div class="search-box p-2">
                                            <input  type="text" class="form-control fs-14" [formControl]="labelSearchControl" style="height: 38px;"  placeholder="{{ 'MAIN.CONTENT.WORKFLOW.LANGUAGE.SEARCH' | translate }}" inputSelectOnFocus [inputSelectOnCreate]="true">
                                            <div class="search-icon me-2"> <img src="assets/img/search-copy.svg" alt=""></div>
                                        </div>
                                        <div *ngFor="let label of filteredLabelList; let index = index" class="ps-2">
                                            <div class="custom-control custom-checkbox custom-checkbox-green">
                                                <input
                                                    type="checkbox"
                                                    class="form-check-input ms-0"
                                                    [id]="index"
                                                    (click) = selectLabels(label)
                                                    [formControlName]="label"
                                                />
                                                <label class="custom-control-label label-form fs-14" [for]="index">
                                                    {{ label }}
                                                </label>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                                
                            </div>
            
                          <div class="search-box">
                            <input type="text" [formControl]="filter" placeholder="{{ 'MAIN.CONTENT.USER-LIST.SEARCH' | translate }}" class="filter-input fs-14">
                            <div class="search-icon"> <img src="assets/img/search.svg" alt=""></div>
                          </div>
                         
                        </div>
            
                        <div class="mt-4 d-flex flex-wrap" *ngIf="filteredLabels?.length">
                            <span class="fs-14 me-2 font-weight-600 ms-1">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.FILTER.SELECTED-LABELS' | translate }} : </span>
                            <span class="fs-12 font-weight-500 label-span me-2 mb-2" *ngFor="let label of filteredLabels">{{label}} <img (click)="deleteLabel(label)" class="label-close cursor-pointer" src="assets/img/close.svg" alt=""></span>
                            <span class="fs-14 font-weight-600 cursor-pointer text-blue" [ngClass]="{'disabled': isLoadingFilter}" (click)="clearAll()">Clear all</span>
                        </div>
                        <div *ngIf="tabIndex == 1 && selectedWorkflowIds?.length" class="d-flex mt-3 mb-2">
                            <label class="fs-14 font-weight-500 me-3 ms-1">
                                {{selectedWorkflowIds.length}} {{ 'MAIN.CONTENT.DASHBOARD.FOLDER.SELECTED' | translate }}</label>
                            <div class="fs-14 font-weight-600 text-blue cursor-pointer" (click)="moveToFolder(selectedWorkflowIds)">
                                <div class="d-flex">
                                    <img src="assets/img/move-to-folder.svg" alt="">
                                    <span class="ms-2">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.MOVE-TO-FOLDER' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table mat-table [dataSource]="dataSource" matSort matTableResponsive>
                        <ng-container matColumnDef="index">
                            <th mat-header-cell *matHeaderCellDef>#</th>
                            <td mat-cell data-column-name="#" *matCellDef="let i = index">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="draft['listName']">
                            <th class="table-fs-14 fs-14 text-dark-grey font-weight-400" [class.col-4]="status!=='modal'" [class.col-5]="status=='modal'" style="padding-left: 20px;" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.NAME' | translate }}</th>
                            <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.DURATION'" *matCellDef="let element" class="fs-15 font-weight-700 d-flex" [class.col-4]="status!=='modal'" [class.col-5]="status=='modal'" style="text-align: start; padding-left: 20px;align-items: center;">
                                <div class="custom-control custom-checkbox custom-checkbox-green me-3" *ngIf="tabIndex == 1">
                                    <input
                                        type="checkbox"
                                        class="form-check-input ms-0"
                                        [id]="element.id"
                                        (click) = selectWorkflowToMove(element)
                                        [checked]="element.isSelected"
                                    />
                                </div>
                                <div>
                                    <div (click)="showJsonSource($event, element)" [ngStyle]="{'pointer-events': !('showSourceBtn' | env) ? 'none' : '' }">{{ element.published?.['listName'] }}</div>
                                    <div *ngIf="!element.published?.['listName']">{{ element.draft?.['listName'] }}</div>
                                    <div *ngIf="element.createdAt" class="description-info font-weight-400" title="{{element.createdAt | localizedDate | async}}">{{ element.createdAt | localizedDateShort | async}}</div>
                                    <div *ngIf="element.createdBy" class="description-info font-weight-400">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.CREATED-BY' | translate }} : {{ getUsersName(element.createdBy)}}</div>
                                    <div style="margin-top: -3px;">
                                        <span class="badge badge-secondary" style="color: #0ae6ff;
                                        background-color: #000F29;" *ngIf="element.badges">CreatedByAI</span>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef class="centered table-fs-14 fs-14 text-dark-grey font-weight-400" [ngClass]="{'col-3': status=='modal','col-2': status!=='modal'}">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.STATUS.TITLE' | translate }}</th>
                            
                            <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS'" *matCellDef="let element" class="d-flex align-items-start justify-content-center flex-column" [ngClass]="{'col-3': status=='modal','col-2': status!=='modal'}">
                                <div>
                                    <span *ngIf="element.published" class="fs-14 me-2">v{{element.published.version}}</span>
                                    <span *ngIf="element.published" class="badge bg-success fs-12 font-weight-400">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.STATUS.PUBLISHED' | translate }}</span>
                                </div>
                                <div class="mt-1">
                                    <span *ngIf="element.draft" class="fs-14 me-2">v{{element.draft.version}}</span>
                                    <span *ngIf="element.draft" class="badge bg-warning fs-12 font-weight-400">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.STATUS.DRAFT' | translate }}</span>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="lastEdited" *ngIf="status!=='modal'">
                            <th mat-header-cell *matHeaderCellDef class="col-2 centered table-fs-14 fs-14 text-dark-grey font-weight-400">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.LAST-EDITED' | translate }}</th>
                            <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS'" *matCellDef="let element" class="col-2 d-flex align-items-start justify-content-center flex-column fs-14 font-weight-400 text-light-black">
                                <div *ngIf="element.published?.updatedAt" title="{{element.published.updatedAt | localizedDate | async}}">
                                    {{ element.published.updatedAt | localizedDateShort | async}}
                                </div>
                                <div *ngIf="element.draft?.updatedAt" class="mt-1" title="{{element.draft.updatedAt | localizedDate | async}}">
                                    {{ element.draft.updatedAt | localizedDateShort | async}}
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="languages" *ngIf="status!=='modal'">
                            <th mat-header-cell *matHeaderCellDef class="col-3 centered table-fs-14 fs-14 text-dark-grey font-weight-400">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.LANGUAGES' | translate }}</th>
                            <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS'" *matCellDef="let element"
                             class="d-flex align-items-start justify-content-center flex-column fs-14 font-weight-400 text-light-black col-2">
                                <div *ngIf="element.published?.translations">
                                    <span *ngFor="let code of element.published.translations | keyvalue;let index = index">
                                        <span *ngIf="code?.key && index<4">{{$any(code.key) | uppercase}}<span *ngIf="index !== (element.published.translations | keyvalue).length-1 && index<3">,</span>
                                    </span>
                                    </span>
                                    <span *ngIf="(element.published.translations | keyvalue).length>=4">...</span>
                                </div>
                                <div *ngIf="element.draft?.translations" class="mt-1">
                                    <span *ngFor="let code of element.draft.translations | keyvalue;let index = index">
                                        <span *ngIf="code?.key && index<4">{{$any(code.key) | uppercase}}<span *ngIf="index !== (element.draft.translations | keyvalue).length-1 && index<3">,</span></span>
                                    </span>
                                    <span *ngIf="(element.draft.translations | keyvalue).length>=4" [dynamicTooltip]="'MAIN.CONTENT.REMOTE.IMAGE-COLLABORATION.REVERT' | translate">...</span>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef class="col-1 table-fs-14 text-center"></th>
                            <td mat-cell *matCellDef="let element" class="col-2 d-flex justify-content-end align-items-center" style="padding-right: 30px;">
    
                                  <app-dropdown title="{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.TITLE' | translate }}" *ngIf="status!=='modal'" style="width: 120px;">
                                    <ng-container *ngIf="!('electron' | env)">
                                        <li role="menuitem">
                                            <a class="dropdown-item" (click)="showWorkflowUsers(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.USERS' | translate }}</a>
                                        </li>
                                        <hr>
                                    </ng-container>
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="element.draft ? open(element,'draft') : createDraft(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.EDIT' | translate }}</a>
                                    </li>
                                    <li role="menuitem" *ngIf="element.draft">
                                        <a class="dropdown-item" (click)="publishWorkflow(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.PUBLISH' | translate }}</a>
                                    </li>
                                    <li role="menuitem" *ngIf="element.published">
                                        <a class="dropdown-item" (click)="openPublishedView(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.PUBLISH-VIEW' | translate }}</a>
                                    </li>
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="moveToFolder(element.id)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.MOVE-TO-FOLDER' | translate }}</a>
                                    </li>
                                    <li role="menu-item">
                                        <a class="dropdown-item text-red" (click)="deleteWorkflow(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.OPTIONS.DELETE' | translate }}</a>
                                    </li>
                                </app-dropdown> 
    
                                <div *ngIf="status=='modal'">
                                    <button class="v-btn btn btn-secondary-outline fs-16 font-weight-500" (click)="selectWorkflow(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.SELECT' | translate }}</button>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <div>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </div>
                    </table>
                    <div *ngIf="((notFolderedSelected && !notFolderedWorkflows?.length) ||  (selectedFolder && !selectedFolderWorkflowList?.length)) && tabIndex == 1" class="mt-5 fs-14 text-grey">{{ 'MAIN.CONTENT.DASHBOARD.FOLDER.NO-WORKFLOW-FOUND' | translate }}</div>
                </div>
            </div>
            
        </div>
    </div>
</div>

<div *ngIf="!isEditorMode && dataLoaded" class="h-100">
    <app-user-dashboard [workflowList]= "normaluserWorkflows" [workflowId]= "workflowId" [publicWorkflow]= "publicWorkflow"></app-user-dashboard>
</div>

<ng-template #empty>
    <div class="d-flex" *ngIf="dataLoaded">
        <div class="col-6"></div>
        <div class="col-6">
            <img src="assets/img/empty.svg" alt="">
        </div>
    </div>
</ng-template>

