<div class="login-wrapper">
    <div class="login-container">
        <div class="logo-container">
            <img *ngIf="!('design' | env).loginLogo" class="logo-image" [ngStyle]="('design' | env).logoStyle" [src]="('design' | env).logo">
            <img *ngIf="('design' | env).loginLogo" class="logo-image" [ngStyle]="('design' | env).loginLogoStyle" [src]="('design' | env).loginLogo">
            <div *ngIf="('design' | env).logoText" class="logo-text ml-2">{{ ('design' | env).logoText }}</div>
        </div>
        <div class="card">
            <div class="card-header pt-1 pb-0" [style.background-color]="('design' | env).loginColor"></div>
            <div class="card-body px-5 py-1">
                <div class="login-primary-title mt-5" *ngIf="('design' | env).addWorkflowToName">{{ 'LOGIN.APP-NAME' | translate:('design' | env) }}</div>
                <div class="login-primary-title mt-5" *ngIf="!('design' | env).addWorkflowToName">{{ ('design' | env).appName }}</div>
                <div class="login-secondary-title mt-2 pb-2">{{ 'LOGIN.TITLE' | translate }}</div>

                <div class="login-form-wrapper" [ngClass]="{'d-none': verification.open}">
                    <form #loginForm="ngForm" id="loginForm" (ngSubmit)="onLogin(loginForm)" class="mt-4">
                        <div class="form-group">
                            <input class="field form-control py-2" placeholder="{{ 'LOGIN.ACCOUNT-PLACEHOLDER' | translate }}" type="text" name="account" #loginAccount="ngModel" [ngClass]="{'is-invalid':loginAccount.errors && loginAccount.touched}" [(ngModel)]="loginUser.account"
                                minlength="2" required>
                            <small class="invalid-feedback mx-2" [hidden]="!(loginAccount.errors ? loginAccount.errors['required'] : false)">{{ 'LOGIN.ACCOUNT-REQUIRED' | translate }}</small>
                            <small class="invalid-feedback mx-2" [hidden]="!(loginAccount.errors ? loginAccount.errors['minlength'] : false)">{{ 'LOGIN.ACCOUNT-SHORT' | translate }}</small>
                        </div>

                        <div class="form-group">
                            <input class="field form-control py-2" placeholder="{{ 'LOGIN.USERNAME-PLACEHOLDER' | translate }}" type="text" name="username" #loginUsername="ngModel" [ngClass]="{'is-invalid':loginUsername.errors && loginUsername.touched}" [(ngModel)]="loginUser.username"
                                minlength="2" required>
                            <small class="invalid-feedback mx-2" [hidden]="!(loginUsername.errors ? loginUsername.errors['required'] : false)">{{ 'LOGIN.USERNAME-REQUIRED' | translate }}</small>
                            <small class="invalid-feedback mx-2" [hidden]="!(loginUsername.errors ? loginUsername.errors['minlength'] : false)">{{ 'LOGIN.USERNAME-SHORT' | translate }}</small>
                        </div>

                        <div class="form-group">
                            <input class="field form-control py-2" placeholder="{{ 'LOGIN.PASSWORD-PLACEHOLDER' | translate }}" type="password" name="password" #loginPassword="ngModel" [ngClass]="{'is-invalid':loginPassword.errors && loginPassword.touched}" [(ngModel)]="loginUser.password"
                                minlength="6" required/>
                            <small class="invalid-feedback mx-2" [hidden]="!(loginPassword.errors ? loginPassword.errors['required'] : false)">{{ 'LOGIN.PASSWORD-REQUIRED' | translate }}</small>
                            <small class="invalid-feedback mx-2" [hidden]="!(loginPassword.errors ? loginPassword.errors['minlength'] : false)">{{ 'LOGIN.PASSWORD-SHORT' | translate }}</small>
                        </div>
                    </form>

                    <div class="form-check align-items-center mb-3">
                        <input type="checkbox" class="form-check-input" id="remember" name="remember" [(ngModel)]="accountNameRemeber">
                        <label class="form-check-label" for="remember" style="color: #212529;">{{ 'LOGIN.REMEMBER-ACCOUNT-NAME' | translate }}</label>
                    </div>
                </div>

                <div class="verification-wrapper my-5" [ngClass]="{'d-block': verification.open, 'd-none': !verification.open}">
                    <div class="mb-3">{{ 'LOGIN.VERIFICATION-SENDED' | translate }}</div>
                    <form #verificationForm="ngForm" id="verificationForm" (ngSubmit)="onVerify(verification.code)" class="mt-4">
                        <div class="form-group">
                            <input class="field form-control py-2" placeholder="{{ 'LOGIN.VERIFICATION-CODE' | translate }}" type="text" name="verificationCode" #verificationCode="ngModel" [(ngModel)]="verification.code" required style="font-size: 14px;" [ngClass]="{'is-invalid':verificationCode.errors && verificationCode.touched}"
                            />
                        </div>
                    </form>
                </div>
            </div>
            <div class="card-footer d-flex flex-row justify-content-between align-items-center">
                <div class="language-dropdown" dropdown #dropdown="bs-dropdown">
                    <button id="language-toggle" class="language-link d-flex flex-row align-items-center" dropdownToggle aria-controls="language-menu">
                        <i class="mi mi-language mr-2"></i>
                        <span>{{ currentLang.code | uppercase }}</span>
                        <i class="mi mi-keyboard-arrow-down ml-2"></i>
                    </button>
                    <ul id="language-menu" *dropdownMenu class="dropdown-menu py-0" role="menu" aria-expanded="false" aria-labelledby="language-toggle">
                        <ng-container *ngFor="let lang of allLangs">
                            <li class="language-item" role="menuitem" *ngIf="currentLang !== lang">
                                <a class="dropdown-item language-link px-2" (click)="onLanguageSelected(lang)">{{ lang.code | uppercase }}</a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
                <button *ngIf="!verification.open" form="loginForm" class="btn btn-dark px-4" [disabled]="isLoginProgress || !loginForm.valid">{{ 'LOGIN.LOGIN-BUTTON' | translate }}</button>
            </div>
        </div>
    </div>
</div>