<ng-container *ngIf="reportList.length == 0 && !isLoading">
    <div class="d-flex align-items-center me-5 ms-5">
        <div class="col-md-4 col-sm-12" style="margin-right: 3rem !important;
    margin-left: 2rem;">
            <p class="fs-32">{{ 'MAIN.CONTENT.REPORT-LIST.NO-REPORT.TITLE' | translate }}</p>
            <div class="fs-16-light">{{ 'MAIN.CONTENT.REPORT-LIST.NO-REPORT.TEXT' | translate }}</div>
            <button *ngIf="('electron' | env)" (click)="importReports()" class="v-btn btn btn-primary mt-3 fs-12 font-weight-600 text-white" style="border-radius:7px; height: 30px;padding-top:5px">IMPORT REPORTS</button>
        </div>
        <div class="col-md-5 col-sm-12">
            <img src="assets/img/empty.svg" alt="">
        </div>
    </div>
</ng-container>
<div class="dashboard-wrapper" *ngIf="reportList.length > 0 && !isLoading" >
    <div class="v-card">
        <div class="card report-header list-header list-header-fixed flex-row justify-content-between align-items-center pe-4 ps-4">
            <div class="d-flex">
                <div class="header fs-20 font-weight-700  pt-1 me-2">{{ 'MAIN.CONTENT.REPORT-LIST.TITLE' | translate }}</div>
                <div class="mt-3 ms-3" *ngIf="('electron' | env)">
                    <button (click)="importReports()" class="v-btn btn btn-primary  fs-12 font-weight-600 text-white" style="border-radius:7px; height: 30px;padding-top:5px">IMPORT REPORTS</button>
                </div>
            </div>
            
            <div *ngIf="!('electron' | env)">
                <button class="v-btn btn btn-primary fs-15 font-weight-600 text-white create-button"
                (click)="export()" [disabled]="isDownloading"> {{ 'MAIN.CONTENT.REPORT.EXPORT' | translate | titlecase }}</button>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-between filter-area mb-4 pe-4" style="padding-top: 125px;">
            <div class="d-flex align-items-center ps-3" [formGroup]="filterForm">
                <div class="d-flex">
                    <div style="position: relative;">
                        <input type="text" placeholder="{{ 'MAIN.CONTENT.REPORT-LIST.PLACEHOLDER' | translate }}" formControlName="nameFilter" class="filter-input fs-14">
                        <div class="search-icon"> <img src="assets/img/search.svg" alt=""></div>
                    </div>
                </div>
                <div class="d-flex ms-3">
                    <div style="position: relative;">
                        <input type="text" placeholder="{{ isEwers ? 'Suche mit Auftrags-Nr. / Serien-Nr.' : 'MAIN.CONTENT.REPORT-LIST.FILTER-ID' | translate }}"  formControlName="labelFilter" class="filter-input fs-14">
                        <div class="search-icon"> <img src="assets/img/search.svg" alt=""></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card list-area">
            <div class="" style="display: flex;
              text-align: center;
              justify-content: center;">
                <table mat-table [dataSource]="dataSource" matSort matTableResponsive>
                    <ng-container matColumnDef="index">
                        <th mat-header-cell *matHeaderCellDef>#</th>
                        <td mat-cell data-column-name="#" *matCellDef="let i = index">
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name" class="col-4 table-fs-14 text-center user-select-none fs-14 text-dark-grey font-weight-400" style="padding-left:20px;">

                            {{ 'MAIN.CONTENT.REPORT-LIST.LIST.WORKFLOW' | translate }}
                        </th>
                        <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.DURATION'" *matCellDef="let element" class="d-flex col-4 fs-15 font-weight-700 align-items-center" style="text-align: start;
                        padding-left: 20px;">
                            <div>
                                <div (click)="showJsonSource($event, element)" [ngStyle]="{'pointer-events': !('showSourceBtn' | env) ? 'none' : '' }">{{element.workflowName}}</div>
                                <div *ngIf="element?.createdAt" class="description-info fs-12 font-weight-400 mb-0">{{element.createdAt | localizedDate | async}}</div>
                                <div *ngIf="element?.['filterValue']" style="margin-top: -3px;" class="mb-1">
                                    <span class="badge badge-secondary pb-1" style="background-color: #eff1f5;
                                    color: #667999;">
                                    {{isEwers ? 'Auftrags-Nr. / Serien-Nr.' : ('MAIN.CONTENT.REPORT-LIST.ID-TAG' | translate) }}
                                     : {{element['filterValue']}}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="version">
                        <th mat-header-cell *matHeaderCellDef class="col-2  table-fs-14 text-center fs-14 text-dark-grey font-weight-400">{{ 'MAIN.CONTENT.REPORT-LIST.LIST.VERSION' | translate }}</th>
                        <td mat-cell [attr.data-column-name]="'APP.MAIN.LOBBY.SESSIONS.STATUS'" *matCellDef="let element" class="col-2 fs-14 font-weight-400 d-flex align-items-center justify-content-center">
                            v{{element.version}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="user">
                        <th mat-header-cell *matHeaderCellDef class="col-2 table-fs-14 text-center fs-14 text-dark-grey font-weight-400">
                            {{ 'MAIN.CONTENT.REPORT-LIST.LIST.SUBMITTED-BY' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element" class="col-2 fs-14 font-weight-400 d-flex align-items-center justify-content-center">
                            <p *ngIf="!element?.guest?.email" class="font-weight-400 mb-0">{{ getUsersName(element.userId) }}</p>
                            <p *ngIf="element?.guest?.email" class="font-weight-400 mb-0">{{element.guest.email}}</p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="col-2 table-fs-14 text-center fs-14 text-dark-grey font-weight-400">
                            {{ 'MAIN.CONTENT.REPORT-LIST.LIST.APPROVAL' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element" class="col-2 fs-14 d-flex align-items-center justify-content-center">
                            <span *ngIf="element?.signature && element?.signature.signed" class="font-weight-600 mb-0" style="color: #24A148;"> {{ 'MAIN.CONTENT.REPORT-LIST.LIST.APPROVED' | translate }} </span>
                            <span *ngIf="element?.signature && !element?.signature?.signed" class="font-weight-600 mb-0 text-dark-grey">{{ 'MAIN.CONTENT.REPORT-LIST.LIST.PENDING' | translate }} </span>

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef class="col-2 table-fs-14 text-center">
                            
                        </th>
                        <td mat-cell *matCellDef="let element" class="col-2 fs-12 font-weight-400 d-flex justify-content-end align-items-center" style="padding-right: 40px;">
                            <button *ngIf="!element?.oldContinueWithQr" class="v-btn btn open-report-button cursor-pointer" (click)="openReport(element)">{{ 'MAIN.CONTENT.REPORT-LIST.LIST.VIEW' | translate }}</button>
                            <div dropdown class="btn-group dropdown-wrapper-list" *ngIf="element?.oldContinueWithQr"
                             style="width: 120px;">
                                <button id="workflow-actions-dropdown-toggle-button" dropdownToggle #workflowActionsToggleBtn 
                                type="button" class="btn btn-primary action-dropdown action-dropdown-show-options p-0" 
                                style="border-color: white !important;width: 70%;">Options</button>
                                <button id="workflow-actions-dropdown-split-button" type="button"  
                                class="btn btn-danger dropdown-toggle dropdown-toggle-split " 
                                style="border-color: white !important;"
                                (click)="$event.stopPropagation(); workflowActionsToggleBtn.click()"
                                aria-controls="workflow-actions-dropdown">
                                </button>
                                <ul id="workflow-actions-dropdown" *dropdownMenu class="dropdown-menu" aria-labelledby="workflow-actions-dropdown-toggle-button">
                                    <li role="menuitem">
                                        <a class="dropdown-item" (click)="openReport(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.SUBMITTED-OPTIONS.SHOW-REPORT' | translate }}</a>
                                    </li>
                                    <li role="menuitem" *ngIf="element?.oldContinueWithQr">
                                        <a class="dropdown-item" (click)="changeToPrefilled(element)">{{ 'MAIN.CONTENT.DASHBOARD.WORKFLOW-TABLE.ACTIONS.SUBMITTED-OPTIONS.CHANGE-PREFILLED' | translate }}</a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <div>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </div>
                </table>
            </div>
        </div>


    </div>

    <ng-container *ngIf="showCountPanel" >
        <div class="card user-information">
            <div class="card-body info">
                <div class="d-flex align-items-center" style="position: absolute;right: 0;bottom: 0;left: 0;top: 0;z-index: 3;">
                    <img src="assets/img/warning_copy.svg" alt="" class="ms-4 me-2">
                    <label class="fs-14 font-weight-500 me-4 text-white">{{ 'MAIN.USER-WORKFLOW.LICENCE-LIMIT' | translate }}</label>
                    <!-- <button class="v-btn btn btn-primary-outline" style="color: white;border-color: white;" >{{ 'MAIN.CONTENT.USER-LIST.DEACTIVATE.CANCEL' | translate }}</button> -->
                </div>
            </div>
        </div>
      </ng-container>
</div>

<ng-template #loading>
    <div class="loader">
        <div class="spinner"></div>
    </div>

</ng-template>