import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { DbService } from '@services/db.service';
import { LoaderService } from '@services/support/loader.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { firstValueFrom } from 'rxjs';
import { AddWebhookModalComponent } from 'src/app/components/modals/add-webhook-modal/add-webhook-modal.component';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  webhooks = []

  constructor(
    private modalService: BsModalService,
    private authService: AuthService,
    private dbService: DbService,
    private http: HttpClient,
    private snackBarService: SnackbarService,
    private loaderService: LoaderService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    const url = environment.endPoints.changeWebhook
    const pending = this.http.post<any>(url, { token: this.authService.currentUser.token, action: "getWebhooks" })
    firstValueFrom(pending)
      .then(result => {
        this.webhooks = result.data.webhooks
      })
      .catch(error => {
        console.log(error)
      })
  }

  onAddWebhook() {
    const initialState = {}
    let addWebhookModalRef = this.modalService.show(AddWebhookModalComponent,  { initialState, backdrop:'static', class:'modal-lg modal-dialog-centered', animated:false })
    addWebhookModalRef.content.onClose.subscribe((res) => {
      addWebhookModalRef.hide()
    })
    addWebhookModalRef.content.onNewWebhook.subscribe(webhook => {
      this.loaderService.show()
      const url = environment.endPoints.changeWebhook
      const pending = this.http.post<any>(url, { token: this.authService.currentUser.token, action: "addWebhook", webhook: webhook })
      firstValueFrom(pending)
        .then(result => {
          addWebhookModalRef.hide()
          webhook.id = result.data.webhookId
          this.webhooks.push(webhook)
        })
        .catch(error => {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.SETTINGS.WEBHOOKS.ERROR'))
        })
        .finally(() => this.loaderService.hide())
    })
  }

  onRemoveWebhook(webhook) {
    const initialState = {
      textMessage : this.translateService.instant('MAIN.CONTENT.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.MESSAGE'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.TITLE'),
      confirmButtonText: this.translateService.instant('MAIN.CONTENT.SETTINGS.WEBHOOKS.REMOVE_WEBHOOK_MODAL.REMOVE'),
      confirmButtonStyle: 'danger',
      oneButtonAvailable: false
    }
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    })
    alertmodalRef.content.onClose.subscribe(res => {
      if (res) {
        this.loaderService.show()
        const url = environment.endPoints.changeWebhook
        const pending = this.http.post<any>(url, { token: this.authService.currentUser.token, action: "removeWebhook", webhook: webhook })
        firstValueFrom(pending)
          .then(result => {
            alertmodalRef.hide()
            this.webhooks = this.webhooks.filter(wh => wh.id !== webhook.id)
          })
          .catch(error => {
            this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.SETTINGS.WEBHOOKS.ERROR'))
          })
          .finally(() => this.loaderService.hide())
      } else {
        alertmodalRef.hide();
      }
    })
  }
}
