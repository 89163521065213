<!-- Initialize step right -->
<div class="widget-properties-section left">
    <div class="d-flex flex-column" *ngIf="widgetForm">
        <!-- <div class="rectangle d-flex">
            <div class="info-p">
                <img src="assets/img/info.svg">
            </div>
            <div class="inform-text">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.INFORM' | translate }}</div>
        </div> -->
        <a class="core-blue fs-16 top-gap back font-weight-600" (click)="back()" *ngIf="!step?.data?.isSection">
            <img src="assets/img/widget-back.svg" class="back-vector"> {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.BACK' | translate }}
        </a>
        <div class="top-gap-30">
            <a class="core-blue fs-14 font-weight-600 back" (click)="showStepFiles()">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.STEP-FILES.VIEW-STEP-FILES' | translate }}</a>
        </div>

        
        <div class="top-gap-30 fs-18 font-weight-600">{{ widgets[type].name | translate }} <span class="fs-16" *ngIf="widgets[type].descriptionInfo">({{widgets[type].descriptionInfo}})</span></div>

        <form [formGroup]="widgetForm" (ngSubmit)="onWidgetFormSubmit(widgetForm)">

            <div class="form-group top-gap-30" *ngIf="widgetProps[type].html">
                <quill-editor format="html" formControlName="html" [modules]="quillConfig.modules"></quill-editor>
                <div *ngIf="widgetForm.controls['html'].errors?.['required']" class="validation-text mb-0">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.LABEL.NO-LABEL' | translate }}</div>
            </div>

            <div class="form-group top-gap-30" *ngIf="widgetProps[type].label">
                <label class="fs-16 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.LABEL.TITLE' | translate }}<span>*</span></label>
                <input type="text" class="form-control" formControlName="label" inputSelectOnFocus>
                <div *ngIf="widgetForm.controls['label'].errors?.['required']" class="validation-text mb-0">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.LABEL.NO-LABEL' | translate }}</div>
            </div>

            <div class="top-gap-30" *ngIf="widgetProps[type].position">
                <label class="label-position fs-16 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.POSITION.TITLE' | translate }}</label>

                <div class="d-flex align-items-center justify-content-between">
                    <div class="fs-14 font-weight-400">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.POSITION.HORIZONTAL' | translate }}</div>
                    <div class="d-flex">
                        <button class="icons" [class.selected]="widgetForm.controls['hPosition'].value === 'start'">
                            <a (click)="widgetForm.controls['hPosition'].setValue('start')" class="normal-icon"><img src="assets/img/horizontal-left.svg"></a>
                            <a (click)="widgetForm.controls['hPosition'].setValue('start')" class="hover-icon"><img src="assets/img/horizontal-left-hover.svg"></a>
                        </button>

                        <button class="icons" [class.selected]="widgetForm.controls['hPosition'].value === 'center'">
                            <a (click)="widgetForm.controls['hPosition'].setValue('center')" class="normal-icon"><img src="assets/img/horizontal-center.svg"></a>
                            <a (click)="widgetForm.controls['hPosition'].setValue('center')" class="hover-icon"><img src="assets/img/horizontal-center-hover.svg"></a>
                        </button>

                        <button class="icons" [class.selected]="widgetForm.controls['hPosition'].value === 'end'">
                            <a (click)="widgetForm.controls['hPosition'].setValue('end')" class="me-0 normal-icon"><img src="assets/img/horizontal-right.svg"></a>
                            <a (click)="widgetForm.controls['hPosition'].setValue('end')" class="me-0 hover-icon"><img src="assets/img/horizontal-right-hover.svg"></a>
                        </button>

                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between vertical-group">
                    <div class="fs-14 font-weight-400">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.POSITION.VERTICAL' | translate }}</div>
                    <div class="d-flex">
                        <button class="icons" [class.selected]="widgetForm.controls['vPosition'].value === 'start'">
                            <a (click)="widgetForm.controls['vPosition'].setValue('start')" class="normal-icon"><img src="assets/img/vertical-top.svg"></a>
                            <a (click)="widgetForm.controls['vPosition'].setValue('start')" class="hover-icon"><img src="assets/img/vertical-top-hover.svg"></a>
                        </button>

                        <button class="icons" [class.selected]="widgetForm.controls['vPosition'].value === 'center'">
                            <a (click)="widgetForm.controls['vPosition'].setValue('center')" class="normal-icon"><img src="assets/img/horizontal-center-1.svg"></a>
                            <a (click)="widgetForm.controls['vPosition'].setValue('center')" class="hover-icon"><img src="assets/img/horizontal-center-1-hover.svg"></a>
                        </button>

                        <button class="icons" [class.selected]="widgetForm.controls['vPosition'].value === 'end'">
                            <a (click)="widgetForm.controls['vPosition'].setValue('end')" class="normal-icon me-0"><img src="assets/img/vertical-bottom.svg"></a>
                            <a (click)="widgetForm.controls['vPosition'].setValue('end')" class="hover-icon me-0"><img src="assets/img/vertical-bottom-hover.svg"></a>
                        </button>
                    </div>
                </div>
            </div>

            <div class="form-group top-gap-30" *ngIf="widgetProps[type].optionalLabel">
                <label class="fs-16 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.LABEL.TITLE' | translate }}</label>
                <input type="text" class="form-control" formControlName="optionalLabel" inputSelectOnFocus>
                <div *ngIf="widgetForm.controls['optionalLabel'].errors?.['required']" class="validation-text mb-0">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.LABEL.NO-LABEL' | translate }}</div>
            </div>

            <div class="form-group top-gap-16" *ngIf="widgetProps[type].placeholder">
                <label class="fs-16 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PLACEHOLDER' | translate }}</label>
                <input type="text" class="form-control" formControlName="placeholder" inputSelectOnFocus>
            </div>

            <div class="form-group top-gap-16" *ngIf="widgetProps[type].options">
                <label class="fs-16 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.OPTIONS.TITLE' | translate }}</label>
                <div *ngFor="let optionGroup of widgetForm.controls['options'].controls; let i=index;">
                    <div class="d-flex top-gap-8" [formGroup]="optionGroup">
                        <input type="text" class="init form-control mt-0" formControlName="option"  inputSelectOnFocus [inputSelectOnCreate]="createMode">
                        <a class="d-flex align-items-center ms-2 delete" style="cursor: pointer" (click)="deleteOption(widgetForm.controls['options'], i)">
                            <img src="assets/img/delete-widget.svg" alt="" class="normal-icon">
                            <img src="assets/img/delete-widget-hover.svg" alt="" class="hover-icon">
                        </a>
                    </div>
                    <p *ngIf="optionGroup.controls['option']?.errors?.['required']" class="validation-text mb-0">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.OPTIONS.NO-LABEL' | translate }}</p>
                </div>
                <p *ngIf="widgetForm.controls['options'].errors?.['uniqueError']" class="validation-text mb-0">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.OPTIONS.MUST-UNIQUE' | translate }}</p>
                <div *ngIf="widgetForm.controls['options'].length === 1 && widgetForm.controls['options'].controls[0].controls['deleteClicked'].value" class="validation-text">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.OPTIONS.DELETE-LAST' | translate }}</div>
                <a (click)="createOption(widgetForm.controls['options'])" class="d-flex align-items-center add-text fs-14 font-weight-600 top-gap-16">
                        <img src="assets/img/add_icon.svg" alt="" class="normal-icon">
                        <img src="assets/img/add_icon_hover.svg" alt="" class="hover-icon">
                        <span class="ms-1">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.OPTIONS.ADD' | translate }}</span>
                </a>
                <div *ngIf="widgetForm.controls['options'].length >= 7" class="inform-text"><img src="assets/img/info.svg" alt=""> {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.OPTIONS.MAX-ADDED' | translate }}</div>

            </div>

            <div class="form-group" [formGroup]="widgetForm.controls['numberFields']" *ngIf="widgetProps[type].numberFields">
                <div class="d-flex">
                    <div class="col-6 me-2">
                        <label class="fs-16 font-weight-600 top-gap-16">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.NUMBER-FIELDS.MIN' | translate }}</label>
                        <div class="d-flex me-2">
                            <input type="number" class="form-control" formControlName="min" inputSelectOnFocus>
                        </div>
                    </div>
                    <div class="col-6 me-2">
                        <label class="fs-16 font-weight-600 top-gap-16">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.NUMBER-FIELDS.MAX' | translate }}</label>
                        <div class="d-flex me-2">
                            <input type="number" class="form-control" formControlName="max" inputSelectOnFocus>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="col-6 me-2">
                        <label class="fs-16 font-weight-600 top-gap-16">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.NUMBER-FIELDS.UNIT' | translate }}</label>
                        <div class="d-flex">
                            <input type="text" class="form-control" formControlName="unit" inputSelectOnFocus>
                        </div>
                    </div>
                    <div class="col-6 me-2">
                        <label class="fs-16 font-weight-600 top-gap-16">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.NUMBER-FIELDS.PRECISION' | translate }}</label>
                        <div class="d-flex me-2">
                            <input type="number" min="0" class="form-control" formControlName="precision" inputSelectOnFocus (wheel)="disableScroll($event)">
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="widgetProps[type].ppeFields">
                <label class="fs-16 font-weight-600 top-gap-16">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PPE-FIELDS.TITLE' | translate }}</label>
                <div class="form-check mb-2 top-gap-16">
                    <div>
                        <input
                        type="radio"
                        class="form-check-input"
                        [value]="'face'"
                        formControlName="ppeFields"
                        [id]="'face'"
                        name="ppeFields"
                      />
                      <label class="form-check-label fs-14 label-form" [for]="'face'">
                        {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PPE-FIELDS.FACE' | translate }}
                      </label>
                    </div>
                    <div class="top-gap-12">
                        <input
                        type="radio"
                        class="form-check-input"
                        [value]="'head'"
                        formControlName="ppeFields"
                        [id]="'head'"
                        name="ppeFields"
                      />
                      <label class="form-check-label fs-14 label-form" [for]="'head'">
                        {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PPE-FIELDS.HEAD' | translate }}
                      </label>
                    </div>
                    
                    <div class="top-gap-12">
                        <input
                        type="radio"
                        class="form-check-input"
                        [value]="'hand'"
                        formControlName="ppeFields"
                        [id]="'hand'"
                        name="ppeFields"
                      />
                      <label class="form-check-label fs-14 label-form" [for]="'hand'">
                        {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PPE-FIELDS.HAND' | translate }}
                      </label>
                    </div>
                </div>
                <div *ngIf="widgetForm.controls['ppeFields'].errors?.['requireCheckedCheckboxes']" class="validation-text mb-0">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PPE-FIELDS.NO-RECOGNITION' | translate }}</div>
            </div>

            <div *ngIf="widgetProps[type].timepicker" class="form-group mt-3">
                <div class="form-check mb-2">
                    <label for="timepicker" class="form-check-label fs-16 font-weight-600 me-1">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.TIME-PICKER' | translate }}</label>
                    <input id="timepicker" type="checkbox" class="form-check-input" formControlName="timepicker">
                </div>
            </div>

            <div class="form-check d-flex align-items-center mb-1" *ngIf="widgetProps[type].qrValidation">
                <div>
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckValidation" formControlName="qrValidation">
                </div>
                <div>
                    <div class="fs-15 font-weight-500">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.QR-ACTIVATION' | translate }}</div>
                </div>
                <img src="assets/img/info-blue.svg" class="ms-2" [dynamicTooltip]="('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.QR-ACTIVATION-INFO-TEXT' | translate )"  placement="right">

            </div>

            <div *ngIf="widgetProps[type].qrfield" class="top-gap-16">
                <input type="text" class="form-control init" formControlName="qrfield"
                 [ngStyle]="{'pointer-events': !widgetForm.controls['qrValidation'].value ? 'none' : ''}"
                  [disabled]="!widgetForm.controls['qrValidation'].value"
                  placeholder="{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-CONNECTIONS.ENTER' | translate }} {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.QR-FIELD' | translate }}">
                <div *ngIf="widgetForm.controls['qrfield'].errors?.['required']" class="validation-text mb-0">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.QR-FIELD-VALIDATION-TEXT' | translate }}</div>
            </div>

            <div class="form-check required-field-part d-flex align-items-start" *ngIf="widgetProps[type].required && !widgetProps[type].form">
                <div>
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" formControlName="required">
                </div>
                <div>
                    <div class="fs-16 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.REQUIRED.TEXT' | translate }}</div>
                    <div class="fs-12 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.REQUIRED.INFORM' | translate }}</div>
                </div>
            </div>

            <div class="top-gap-16 mb-4" *ngIf="widgetProps[type].imgUrl">
                <label class="image-label fs-16 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.TITLE' | translate }}<span>*</span></label>
                <input type="file" class="d-none" accept="image/png,image/jpeg" onclick="this.value = null" (change)="chooseButtonUpload('image', $event, widgetForm.controls['imgUrl'],widgetForm.controls['imgName'])" #imageInput>
                <input type="file" class="d-none" accept="image/png,image/jpeg" onclick="this.value = null" (change)="chooseButtonUpload('image', $event, tempUploadForm.controls['imgUrl'],tempUploadForm.controls['imgName'])" #changeInput>
                <div class="upload-drop-zone top-gap-16" filedrop fileType="image" (filesDropped)="dragDropUpload('image', $event,widgetForm.controls['imgUrl'],widgetForm.controls['imgName'])" accept="image/png,image/jpeg" id="drop-zone">
                    <div class="upload-icon mt-3"><img src="assets/img/upload-icon.svg"></div>
                    <div class="fs-14 font-weight-700 top-gap-8">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.DRAG-DROP' | translate }}</div>
                    <div class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.INFORM' | translate }}</div>
                    <div class="file-name-container top-gap-16" *ngIf="widgetForm.controls['imgName']?.value">
                        <label class="fs-14">{{widgetForm.controls['imgName'].value}}</label>
                    </div>
                    <ng-container *ngIf="!('electron' | env)">
                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <div class="upload-button" [ngClass]="{'mb-0': widgetForm.controls['imgName']?.value}">
                                <button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="imageInput.click()" *ngIf="!widgetForm.controls['imgName']?.value">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.CLICK.UPLOAD' | translate }}</button>
                                <button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="changeInput.click()" *ngIf="widgetForm.controls['imgName']?.value">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.CLICK.CHANGE' | translate }}</button>
                            </div>
                            <div *ngIf="widgetForm.controls['imgName']?.value" class="mb-4"><button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="editImage()">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.CLICK.EDIT' | translate }}</button></div>  
                        </div>
                          
                    </ng-container>
                    <ng-container *ngIf="('electron' | env)">
                        <div class="upload-button mb-0">
                            <button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="chooseFileAndGetPath('image' ,widgetForm.controls['imgUrl'], widgetForm.controls['imgName'])" *ngIf="!widgetForm.controls['imgName']?.value">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.CLICK.UPLOAD' | translate }}</button>
                            <button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="chooseFileAndGetPath('image', widgetForm.controls['imgUrl'], widgetForm.controls['imgName'])" *ngIf="widgetForm.controls['imgName']?.value">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.CLICK.CHANGE' | translate }}</button>
                        </div>
                        <div class="upload-button" *ngIf="widgetForm.controls['imgName']?.value"><button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="openFileRobot2(widgetForm.controls['imgName'].value, widgetForm.controls['imgUrl'].value, widgetForm.controls['imgUrl'], widgetForm.controls['imgName'])">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.CLICK.EDIT' | translate }}</button></div>    
                    </ng-container>
                </div>
                <div *ngIf="widgetForm.controls['imgUrl'].errors?.['required']" class="validation-text mb-0">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.NO-IMAGE' | translate }}</div>
            </div>

            <div class="top-gap-16 mb-4" *ngIf="widgetProps[type].images">
                <label class="image-label fs-16 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.GALLERY.TITLE' | translate }}<span>*</span></label>
                <input type="file" multiple class="d-none" accept="image/png,image/jpeg" onclick="this.value = null" (change)="uploadGallery('image', $event, widgetForm.controls['images'],widgetForm.controls['imgName'])" #imageInput>
                <input type="file" class="d-none" accept="image/png,image/jpeg" onclick="this.value = null" (change)="chooseButtonUpload('image', $event, tempUploadForm.controls['images'],tempUploadForm.controls['imgName'])" #changeInput>

                <div class="upload-drop-zone top-gap-16 mb-1" filedrop fileType="image" (filesDropped)="dragDropUploadGallery('image', $event,widgetForm.controls['images'])" accept="image/png,image/jpeg" id="drop-zone">
                    <div class="upload-icon"><img src="assets/img/upload-icon.svg"></div>
                    <div class="fs-14 font-weight-700 top-gap-8">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.DRAG-DROP' | translate }}</div>
                    <div class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.INFORM' | translate }}</div>
                    <ng-container *ngIf="!('electron' | env)">
                        <div class="upload-button mb-4">
                            <button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="imageInput.click()" *ngIf="!widgetForm.controls['imgName']?.value">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.CLICK.UPLOAD' | translate }}</button>
                            <button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="changeInput.click()" *ngIf="widgetForm.controls['imgName']?.value">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.CLICK.CHANGE' | translate }}</button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="('electron' | env)">
                        <div class="upload-button mb-0">
                            <button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="chooseFileAndGetPath('image' ,widgetForm.controls['imgUrl'], widgetForm.controls['imgName'])" *ngIf="!widgetForm.controls['imgName']?.value">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.CLICK.UPLOAD' | translate }}</button>
                            <button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="chooseFileAndGetPath('image', widgetForm.controls['imgUrl'], widgetForm.controls['imgName'])" *ngIf="widgetForm.controls['imgName']?.value">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.CLICK.CHANGE' | translate }}</button>
                        </div>
                        <div class="upload-button" *ngIf="widgetForm.controls['imgName']?.value"><button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="openFileRobot2(widgetForm.controls['imgName'].value, widgetForm.controls['imgUrl'].value, widgetForm.controls['imgUrl'], widgetForm.controls['imgName'])">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.UPLOAD.CLICK.EDIT' | translate }}</button></div>    
                    </ng-container>
                </div>

                <div class="fs-12 text-grey" [ngClass]="{'mb-3':widgetForm.controls['images'].value?.length !==0}">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.GALLERY.VALIDATION-TEXT' | translate }}</div>
                <div *ngIf="widgetForm.controls['images'].value?.length == 0" class="validation-text mb-0">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.IMAGE.NO-IMAGE' | translate }}</div>

                <div *ngIf="widgetForm.controls['images'].value?.length" cdkDropList (cdkDropListDropped)="drop($event, widgetForm.controls['images'].value)">
                    <div *ngFor="let image of widgetForm.controls['images'].value; let index = index" cdkDrag class="fs-14 font-weight-400 mb-1">
                        <div class="d-flex justify-content-between">
                            <div class="col-9 d-flex align-items-center me-1">
                                <img src="assets/img/drag-gallery.svg" alt="" cdkDragHandle class="handle">
                                <div class="multiple-image-container">
                                    <div class="multiple-image-div">
                                        <img [src]="image.imgUrl" alt="" class="form-image">
                                    </div>
                                </div>
                                <span class="ms-2 long-text-settings">{{image.imgName}}</span>
                            </div>
                            <div class="d-flex col-3 gallery-buttons-container">
                                <div class="gallery-buttons me-2 cursor-pointer" (click)="editGalleryImage(widgetForm.controls['images'],image, index)">
                                    <img src="assets/img/edit-blue.svg" class="mb-1" alt="" class="normal-icon">
                                    <img src="assets/img/edit-white.svg" class="mb-1" alt="" class="hover-icon">

                                </div>
                                <div class="gallery-buttons cursor-pointer" (click)="deleteGalleryImage(widgetForm.controls['images'],image,index)">
                                    <img src="assets/img/delete-widget.svg" alt="" class="normal-icon">
                                    <img src="assets/img/delete-widget-hover.svg" alt="" class="hover-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="top-gap-16 mb-4" *ngIf="widgetProps[type].videoUrl">
                <label class="fs-16 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.VIDEO.TITLE' | translate }}<span>*</span></label>
                <input type="file" class="d-none" accept="video/*" (change)="chooseButtonUpload('video', $event, widgetForm.controls['videoUrl'],widgetForm.controls['videoName'])" #videoInput>
                <div class="upload-drop-zone top-gap-16" filedrop fileType="video" (filesDropped)="dragDropUpload('video', $event,widgetForm.controls['videoUrl'],widgetForm.controls['videoName'])" accept="video/*" id="drop-zone">
                    <div class="upload-icon"><img src="assets/img/upload-icon.svg"></div>
                    <div class="fs-14 font-weight-700 top-gap-8">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.VIDEO.UPLOAD.DRAG-DROP' | translate }}</div>
                    <div class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.VIDEO.UPLOAD.INFORM' | translate }}</div>
                    <div class="file-name-container top-gap-16" *ngIf="widgetForm.controls['videoName']?.value">
                        <label class="fs-14">{{widgetForm.controls['videoName'].value}}</label>
                    </div>
                    <div class="upload-button mb-4" *ngIf="!('electron' | env)"><button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="videoInput.click()">{{!widgetForm.controls['videoName']?.value ? ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.VIDEO.UPLOAD.CLICK.UPLOAD' | translate) : ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.VIDEO.UPLOAD.CLICK.CHANGE' | translate)}}</button></div>
                    <div class="upload-button" *ngIf="('electron' | env)"><button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="chooseAndCopyWorkflowFile('video', widgetForm.controls['videoUrl'], widgetForm.controls['videoName'])">{{!widgetForm.controls['videoName']?.value ? ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.VIDEO.UPLOAD.CLICK.UPLOAD' | translate) : ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.VIDEO.UPLOAD.CLICK.CHANGE' | translate)}}</button></div>
                </div>
                <div *ngIf="widgetForm.controls['videoUrl'].errors?.['required']" class="validation-text mb-0">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.VIDEO.NO-IMAGE' | translate }}</div>
            </div>

            <div class="top-gap-16 mb-4" *ngIf="widgetProps[type].pdfUrl">
                <label class="fs-16 font-weight-600">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PDF.TITLE' | translate }}<span>*</span></label>
                <input type="file" class="d-none" accept=".pdf" (change)="chooseButtonUpload('pdf', $event, widgetForm.controls['pdfUrl'],widgetForm.controls['pdfName'])" #pdfInput>
                <div class="upload-drop-zone top-gap-16" filedrop fileType="pdf" (filesDropped)="dragDropUpload('pdf', $event,widgetForm.controls['pdfUrl'],widgetForm.controls['pdfName'])" accept=".pdf" id="drop-zone">
                    <div class="upload-icon"><img src="assets/img/upload-icon.svg"></div>
                    <div class="fs-14 font-weight-700 top-gap-8">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PDF.UPLOAD.DRAG-DROP' | translate }}</div>
                    <div class="fs-14 text-dark-grey">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PDF.UPLOAD.INFORM' | translate }}</div>
                    <div class="file-name-container top-gap-16" *ngIf="widgetForm.controls['pdfName']?.value">
                        <label class="fs-14">{{widgetForm.controls['pdfName'].value}}</label>
                    </div>
                    <div class="upload-button mb-4" *ngIf="!('electron' | env)"><button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="pdfInput.click()">{{!widgetForm.controls['pdfName']?.value ? ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PDF.UPLOAD.CLICK.UPLOAD' | translate) : ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PDF.UPLOAD.CLICK.CHANGE' | translate)}}</button></div>
                    <div class="upload-button" *ngIf="('electron' | env)"><button class="vsight-button v-btn btn btn-primary top-gap-12 font-weight-600 mb-2" (click)="chooseAndCopyWorkflowFile('pdf', widgetForm.controls['pdfUrl'], widgetForm.controls['pdfName'])">{{!widgetForm.controls['pdfName']?.value ? ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PDF.UPLOAD.CLICK.UPLOAD' | translate) : ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PDF.UPLOAD.CLICK.CHANGE' | translate)}}</button></div>
                </div>
                <div *ngIf="widgetForm.controls['pdfUrl'].errors?.['required']" class="validation-text mb-0">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.PDF.NO-IMAGE' | translate }}</div>
            </div>

            <div class="form-group top-gap-16" *ngIf="widgetProps[type].form">
                <app-table-form-properties 
                [data]="widgetForm.value['tableData']" 
                [selectedCell]="previewEventData?.selectedCell" 
                (updated)="onUpdateTableWidget($event)"
                [(workflowTranslations)]="workflowTranslations" 
                (workflowTranslationsChange)="workflowTranslationsChange.emit($event)"
                [deletedRow]="previewRowEventData"
                [deletedColumn]="previewColumnEventData">              
                </app-table-form-properties>                
            </div>

            <div class="form-group top-gap-16" *ngIf="widgetProps[type].formData">
                <div class="inform-text"><img src="assets/img/info.svg" alt=""> {{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.OPTIONS.MAX-ADDED' | translate }}</div>
            </div>

            <div class="top-gap-16" *ngIf="widgetProps[type].workflow">
                <div *ngIf="widgetForm.controls.workflow?.value" class="mb-2"><span class="fs-14 font-weight-600">Selected Workflow : </span><span class="fs-14">{{widgetForm.controls.workflow.value.workflowName}}</span> </div>
                <button class="v-btn btn btn-primary fs-12 font-weight-600 text-white" style="border-radius: 10px;
                height: 36px;" (click)="selectWorkflowToConnect(widgetForm.controls.workflow?.value)">{{ widgetForm.controls.workflow?.value ? 'Change Workflow' : 'Select Workflow'}}</button>
            </div>
        </form>
    </div>

    <div class="row widget-list-row mx-0" *ngIf="!widgetForm">
        <ng-container *ngFor="let widget of widgets | objectToArray:'type'">
        <div class="col-6 widget-col" *ngIf="widget.showWidget"
            [dynamicTooltip]="widget.comingSoon ? ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-DESCRIPTION.COMING-SOON' | translate ) : ('MAIN.CONTENT.WORKFLOW.WORKFLOW-STEPS.WIDGET-PROPERTIES.WIDGET-DESCRIPTION.FULLSCREEN' | translate )" 
            [tooltipDisabled]="!widget.comingSoon && !(widget.requiresFullscreen && layoutCount !== 1)"
            [tooltipDelay]="200" [ngClass]="{'d-none': (widget.type === 'ppe' && ('electron' | env)) || (widget.type === 'workflow' && !nestedWorkflowsAvailable) || ((widget.type === 'pdp' || widget.type === 'pdt') && !tubitakFeaturesAvailable)}">
            <div class="widget-card" tabindex="0" (click)="(widget.comingSoon || (widget.requiresFullscreen && layoutCount !== 1)) ? null : onWidgetSelect(widget)" [ngClass]="{ 'selected-widget': type === widget.type, 'widget-card-disabled': widget.comingSoon || (widget.requiresFullscreen && layoutCount !== 1) }">
                <div class="icon-wrapper">
                    <div class="d-flex align-items-center justify-content-center">
                        <img [src]="widget.icon">
                    </div>
                </div>
                <div class="info">
                    <div class="fs-14 font-weight-700 text-blue">{{widget.name | translate}}</div>
                    <div class="fs-12 text-dark-grey">{{ widget.description }}</div>
                </div>
                <div class="disabled-widget"></div>
            </div>
        </div>
        </ng-container>
    </div>
</div>