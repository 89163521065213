import { Injectable } from '@angular/core';

import { DbService } from './db.service';
import { User } from '../models/User';
import { filter, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUser: User = null;

  user: Observable<User>;

  userForGuard: Observable<User>;
  guestForGuard: Observable<any>;

  constructor(private dbService: DbService) {
    this.user = this.dbService.currentUserSource.asObservable().pipe(
      tap(user => { this.currentUser = user }),
      filter(users => !!users)
    );
    this.userForGuard = this.dbService.userForGuardSource.asObservable();
    this.guestForGuard = this.dbService.guestForGuardSource.asObservable();
  }

  getAccountData() {
    return this.dbService.getAccountData()
  }

  loginToFirebase(token: string) {
    return this.dbService.loginToFirebase(token)
  }

  getToken() {
    return this.dbService.getToken()
  }

  getAuth(): Observable<boolean> {
    return this.dbService.getAuth()
  }

  login(account_name: string, username: string, password: string): Promise<any> {
    return this.dbService.login(account_name, username, password)
  }

  logout() {
    return this.dbService.logout()
  }

  verifyLoginCode(account_name: string, username: string, password: string, code: string) {
    return this.dbService.verifyLoginCode(account_name, username, password, code)
  }
}
