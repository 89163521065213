import { Injectable } from '@angular/core';
import { saveAs } from "file-saver";

import { HttpClient } from '@angular/common/http';
import { DbService } from '@services/db.service';

@Injectable({
  providedIn: 'root'
})
export class FileShareService {

  constructor(
    private dbService: DbService,
    private http: HttpClient
  ) { }

  async downloadFile(url: string, filename: string) {
    return this.http.get(url, {observe: 'response', responseType: 'blob'}).toPromise().then(response => {
      saveAs(response.body, filename);
      return;
    });
  }

  uploadWorfklowFile(file: File, workflowId: string, stepId: string, fileId: string): Promise<string> {
    return this.dbService.uploadWorfklowFile(file, workflowId, stepId, fileId)
  }

  convertBase64toFile(content: string, contentType: string, filename: string): File {
    const sliceSize = 512;
    // Method which converts base64 to binary
    const byteCharacters = window.atob(content);
    const byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
 
    // Method which converts byteArrays to blob
    const blob = new Blob(byteArrays, { type: contentType });

    // File constructor for create file
    let f;
    try {
      f = new File([blob], filename, { type: contentType, lastModified: (new Date()).getTime() });
    } catch(error) {
      const lastModifiedDate = new Date();
      Object.defineProperties(blob, {
        name: {
          value: filename
        },
        lastModifiedDate: {
          value: lastModifiedDate
        },
        lastModified: {
          value: lastModifiedDate.getTime()
        },
        toString: {
          value() {
            return '[object File]'
          }
        }
      });
      f = blob;
    }
    return f;
  }

  convertCanvasToJPEGFile(canvas: HTMLCanvasElement, filename: string, compressionRatio: number): Promise<File> {
    return new Promise<File>(resolve => {
      if (canvas.toBlob) {
        canvas.toBlob(blob => {
          resolve(new File([blob], filename, { type: "image/jpeg", lastModified: (new Date()).getTime() }));
        }, "image/jpeg", compressionRatio);
      } else {
        resolve(this.convertBase64toFile(canvas.toDataURL("image/jpeg", compressionRatio).slice('data:image/jpeg;base64,'.length), "image/jpeg", filename));
      }
    });
  }

  saveBase64File(content: string, contentType: string, filename: string) {
    const sliceSize = 512;
    // Method which converts base64 to binary
    const byteCharacters = window.atob(content);
    const byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    saveAs(new Blob(byteArrays, { type: contentType }), filename);
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Str = reader.result.toString();
        const base64StrWithoutHeader = base64Str.slice(base64Str.indexOf(',') + 1);
        resolve(base64StrWithoutHeader);
      }
      reader.onerror = error => reject(error);
    })
  }

  saveFile(file: File) {
    saveAs(file);
  }

}
