import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-report-file',
  templateUrl: './report-file.component.html',
  styleUrls: ['./report-file.component.scss']
})
export class ReportFileComponent implements OnInit {

  authSubscription: Subscription

  isAuthenticated
  isAdmin

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private snackBarService: SnackbarService,
    private translateService: TranslateService) {}

  ngOnInit(): void {
    let param
    let step
    let key
    let index

    this.route.queryParams.subscribe(res => {
      param =  res['report'] ? res['report'] : ''
      step = res['step'] ? res['step'] : ''
      key = res['key'] ? res['key'] : ''
      index = res['index'] ? res['index'] : ''
    })

    this.authService.user.subscribe((user) => {
        if (user.role == 'admin' || user.role == 'coadmin' || user.coadmin) {
          this.router.navigate(['/reports'], { relativeTo: this.route, queryParams: { report: param,step:step,key:key, index: index ? index : null}, queryParamsHandling: 'merge', skipLocationChange: false })
        } else {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.REPORT-LIST.PERMISSION-ERROR'))
          this.router.navigate(['/'])
        }
    })
  }

}
