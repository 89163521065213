import { Component, OnInit } from '@angular/core';
import { MultilanguageService } from '@services/support/multilanguage.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationService } from 'src/app/services/support/navigation.service';
import { LoaderService } from '@services/support/loader.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  userSub: Subscription;
  user: User

  currentLang;
  allLangs = [];
  showLangs: boolean = false;
  langSub: Subscription = null;
  version

  constructor(
    private navigationService: NavigationService,
    private authService: AuthService,
    // public translateService: TranslateService,
    private multilanguageService:MultilanguageService,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.userSub = this.authService.user.subscribe((user) => {
      this.user = user;
    });
    this.version = environment.version

    this.currentLang = this.multilanguageService.currentLang;
    this.allLangs = this.multilanguageService.allLangs;
    this.langSub = this.multilanguageService.onLangChange.subscribe(change => {
      this.currentLang = change.lang;
      // console.log(this.currentLang,'current');
    });
  }

  ngOnDestroy(): void {
    if (this.userSub) { this.userSub.unsubscribe() }
  }

  toggleSidebar() {
    this.navigationService.toggleSidebar();
  }

  onLanguageSelected(lang: any) {
    this.multilanguageService.setCurrentLanguage(lang.code);
  }

  onLogout() {
    const initialState= {
      textMessage : this.translateService.instant('MAIN.NAVBAR.LOGOUT.TEXT-MESSAGE.MESSAGE'),
      confirmButtonText : this.translateService.instant('MAIN.NAVBAR.LOGOUT.TEXT-MESSAGE.CONFIRM'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.LOGOUT')
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    })
    alertmodalRef.content.onClose.subscribe((res) => {
      if (res) {
        this.loaderService.show();
        this.authService.logout().finally(() => {
          this.loaderService.hide();
        });
      }else{
        alertmodalRef.hide()
      }
    });
  }
}
