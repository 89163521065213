import { Injectable } from '@angular/core';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MultilanguageService {

  currentLang = { code: 'en', name: 'English' };
  allLangs = [
    { code: 'en', name: 'English' },
    { code: 'tr', name: 'Türkçe' },
    { code: 'de', name: 'Deutch' },
    { code: 'cz', name: 'Czech' },
    { code: 'sk', name: 'Slovak' }
  ];

  onLangChange: Observable<{ lang: any, translations: any }>;
  private onLangChangeSource = new BehaviorSubject<{ lang: any, translations: any }>(null);

  constructor(
    public translateService: TranslateService
  ) {
    this.onLangChange = this.onLangChangeSource.asObservable().pipe(filter(l => l !== null));

    // Add current languages to translate service
    // Set default language as English
    this.translateService.setDefaultLang('en');
    const languages = [];
    this.allLangs.forEach((lang) => {
      translateService.setTranslation(lang.code, require(`../../../assets/i18n/${lang.code}.json`));
      languages.push(lang.code);
    });
    this.translateService.addLangs(languages);
    const localLang = localStorage.getItem('language');
    const browserLang = this.translateService.getBrowserLang();

    let localLanguageItem;
    let browserLangItem;
    // Look for language key in local storage
    if (localLang && (localLanguageItem = this.allLangs.find(l => l.code === localLang))) {
      this.translateService.use(localLang);
      this.currentLang = localLanguageItem;
      // If there is no config saved in local storage, Look for browser language
    } else if (browserLang && (browserLangItem = this.allLangs.find(l => l.code === browserLang))) {
      this.translateService.use(browserLang);
      this.currentLang = browserLangItem;
      // Else, use English
    } else {
      this.translateService.use('en');
      this.currentLang = { code: 'en', name: 'English' }
    }
    this.onLangChangeSource.next({ lang: this.currentLang, translations: this.translateService.translations });
    // Change currentLang and notify subscribers when lang changed
    this.translateService.onLangChange.subscribe((change: LangChangeEvent) => {
      const lng = this.translateService.currentLang
      this.currentLang = this.allLangs.find(l => l.code === lng);
      this.onLangChangeSource.next({ lang: this.currentLang, translations: change.translations });
      // console.log(this.onLangChangeSource, "on lang change source");
    });
  }

  setCurrentLanguage(code: string) {
    if (code && this.allLangs.findIndex(l => l.code == code) > -1) {
      this.translateService.use(code);
      localStorage.setItem('language', code);
    }
  }

  getInstantTranslation(key: string,interpolateParams:any = null) {
    if(interpolateParams) {
      return this.translateService.instant(key,interpolateParams);
    }
    return this.translateService.instant(key);
  }

}