import { Component, ContentChild, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Workflow, WorkflowVersion } from 'src/app/models/Workflow';
import { LoaderService } from 'src/app/services/support/loader.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { WorkflowUsersModalComponent } from 'src/app/components/modals/workflow-users-modal/workflow-users-modal.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DetailPageService } from 'src/app/services/detail-page.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { WorkflowComponent } from '../workflow/workflow.component';
import { debounceTime, Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import {TranslateService} from "@ngx-translate/core";
import { SnackbarService } from '@services/support/snackbar.service';
import { AuthService } from '@services/auth.service';
import { DbService } from '@services/db.service';
import { AppService } from '@services/support/app.service';
import { JsonViewerComponent } from 'src/app/components/modals/json-viewer/json-viewer.component';
import { DefaultLanguageModalComponent } from 'src/app/components/modals/default-language-modal/default-language-modal.component';
import { WorkflowLanguageService } from '@services/workflow-language.service';
import languagesJson from '../../../../../assets/languages.json';
import { UserDefaultContentLanguageModalComponent } from 'src/app/components/modals/user-default-content-language-modal/user-default-content-language-modal.component';
import { environment } from 'src/environments/environment';
import { User } from '@models/User';
import { OpenAiModalComponent } from 'src/app/components/modals/open-ai-modal/open-ai-modal.component';
import { AnimationItem, FilterSizeConfig } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { UntypedFormGroup } from '@angular/forms';
import { FolderInfoModalComponent } from 'src/app/components/modals/folder-info-modal/folder-info-modal.component';
import { Folder } from '@models/Folder';
import { FolderListModalComponent } from 'src/app/components/modals/folder-list-modal/folder-list-modal.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  @Input() status;
  @Input() stepData?;
  @Input() publicWorkflow?;
  @Input() workflowId?

  @Output() workflowSelected? : EventEmitter<any> = new EventEmitter();

  @ViewChild('optionsDropdown') optionsDropdown?: any;
  @ViewChild('menu') menu: any;
  @ViewChild(MatSort, { static: false })
  set sort(sorter: MatSort) {
    this.dataSource.sort = sorter;
  }

  dataSource = new MatTableDataSource<any>()
  displayedColumns: string[] = [];
  filter = new UntypedFormControl()

  workflows: Workflow[] = []
  userWorkflows: WorkflowVersion[] = []
  normaluserWorkflows: WorkflowVersion[] = []

  subscriptions: Subscription[] = []
  dataLoaded = false;
  isLoading: boolean = true;
  myWorkflows: boolean = false
  workflow : any;

  isAdmin: boolean = true

  downloadUrl

  allUsers: User[] = []
  showLoadingAI = false
  optionsi: AnimationOptions = {
    path: '/assets/img/process.json',
  };

  config : FilterSizeConfig = {
    height: "50px",
    width:"",
    x: "",
    y:""
  }

  isAIAvailable = false
  public invoiceForm: UntypedFormGroup;

  allLabels  = []
  filteredLabels= []
  @ContentChild('searchInput') searchInput: ElementRef;

  labelGroup = new UntypedFormGroup({})
  filteredLabelWorkflows = []
  filteredNameWorkflows = []
  isLoadingFilter = false
  labelSearchControl = new UntypedFormControl()
  filteredLabelList = []

  easyAccessUpdated = false

  isEditorMode = false
  isInitialized = false

  tabIndex = 0
  folders: Folder[] = []
  notFolderedWorkflows = []

  selectedWorkflowIds = []
  selectedFolder: Folder
  selectedFolderWorkflowList = []
  allWorkflows = []
  notFolderedSelected = false

  hideConnections
  isQrAvailable
  hasVideoFlow
  workflowSections

  constructor(
    private dbService: DbService,
    public loaderService: LoaderService,
    private workflowService: WorkflowService,
    private detailPageService: DetailPageService,
    private modalService: BsModalService,
    private renderer: Renderer2,
    private router: Router,
    private translateService: TranslateService,
    private snackBarService: SnackbarService,
    private authService: AuthService,
    private appService: AppService,
    private workflowLanguageService: WorkflowLanguageService,
  ) {
    this.subscriptions.push(this.authService.user.subscribe(user => {
      this.isAdmin = user.role === 'admin' || user.role === 'coadmin' || user.coadmin || user.editorAccess
    }))

    
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.optionsDropdown ?.nativeElement && e.target !== this.menu ?.nativeElement) {
        this.workflows.forEach(res => {
          if(res){
            res['show'] = false
          }
        })
      }
    })
    // this.fetch((data) => this.model = data);
   }

   toggle(): void {
    if (this.searchInput?.nativeElement) {
      setTimeout(() => {
        (this.searchInput.nativeElement as HTMLInputElement).focus();
      });
    }
  }

  stop(event) {
    event.stopPropagation()
  }
  
  sendPrefilledWorkflow() {

  }

  ngOnInit(): void {
    this.subscriptions.push(this.appService.isEditorMode.subscribe(res => {
      this.dataLoaded = false
      this.tabIndex = 0
      if(this.isAdmin) {
        if (res !== this.isEditorMode) {
          this.isEditorMode = res
        }
      }
      this.initialize()
    }))
  }

  initialize() {
      if(this.status=='modal'){
        this.displayedColumns =[
          "draft['listName']",
          "status",
          "languages",
          "actions"
        ]
      }else{
        this.displayedColumns =[
          "draft['listName']",
          "status",
          "lastEdited",
          "languages",
          "actions"
        ]
      }
      if (this.isAdmin && this.isEditorMode) {
        this.getWorkflowList()
      } else{
        this.getMyWorkflowList()
      }

    this.filter.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      if (res || this.selectedFolder || this.notFolderedSelected) {
        this.filterAll(res.toLowerCase())
      } else {
        this.filteredNameWorkflows = []
        this.filterLabels()
        this.dataSource = this.filteredLabelWorkflows.length ? new MatTableDataSource(this.filteredLabelWorkflows) :  (this.isAdmin && this.isEditorMode ? new MatTableDataSource(this.workflows) : new MatTableDataSource(this.userWorkflows))
        // if(this.tabIndex == 1 && this.notFolderedSelected) {
        //   this.showNotFolderedWorkflows()
        // }
      }
    })
    if (!environment.electron && this.isAdmin && this.isEditorMode) {
      this.workflowService.getAllUsersForWorkflow().then(result => {
        this.allUsers = result.users
      })
    }

    this.authService.getAccountData().then(account => {
      this.isAIAvailable = account.data.accountData.features?.workflowai
      this.hideConnections = !account.data.accountData.add_ons?.workflowconnections
      this.isQrAvailable = account.data.accountData.features?.workflowqr
      this.hasVideoFlow = account.data.accountData.add_ons?.videoflow
      this.workflowSections = account.data.accountData.add_ons?.workflowsections
    })

    if(this.isAdmin && this.isEditorMode) {
      this.workflowService.getWorkflowAccountLabels().then(workflowLabels => {
        this.allLabels = workflowLabels
        this.filteredLabelList = this.allLabels
        this.allLabels.forEach(label => {
          let labelControl = new UntypedFormControl (false)
          this.labelGroup.addControl(label, labelControl)
        })
      })
    }else{
      // this.userWorkflows.forEach(wf => {
      //   this.allLabels.push(wf.labels)
      // })
      this.filteredLabelList = this.allLabels
    }

    this.subscriptions.push(this.labelGroup.valueChanges.subscribe(filterLabelRes => {
      this.filteredLabelWorkflows = []
      this.filterLabels()
      
    }))

    this.subscriptions.push(this.labelSearchControl.valueChanges.subscribe(searchLabel => {
      if(searchLabel) {
        this.filteredLabelList = this.allLabels.filter(res => res.toLowerCase().includes(searchLabel.toLowerCase()))
      }else{
        this.filteredLabelList = this.allLabels
      }
    }))
  }

  filterLabels(){
    if(this.filteredLabels.length) {
      if(this.isAdmin && this.isEditorMode) {
        if(this.filteredNameWorkflows.length) {
          this.filteredNameWorkflows.forEach(res => {
            if(res.published?.labels || res.draft?.labels) {
              this.filteredLabels.forEach(labelfilt => {
                if((res.published?.labels?.length || res.draft?.labels?.length) && (res.published?.labels.includes(labelfilt) || res.draft?.labels.includes(labelfilt)) && (this.filteredLabelWorkflows.length ? this.filteredLabelWorkflows.every(id => id.id !== res.id) : true)) {
                   this.filteredLabelWorkflows.push(res)
                   if(this.selectedFolder) {
                    this.filteredLabelWorkflows = this.filteredLabelWorkflows.filter(wf => this.selectedFolder.workflows.includes(wf.id))
                   }
                }
              })
            }
          })
        }else{
          this.workflows.forEach(res => {
            if(res.published?.labels || res.draft?.labels) {
              this.filteredLabels.forEach(labelfilt => {
                if((res.published?.labels?.length || res.draft?.labels?.length) && (res.published?.labels.includes(labelfilt) || res.draft?.labels.includes(labelfilt)) && (this.filteredLabelWorkflows.length ? this.filteredLabelWorkflows.every(id => id.id !== res.id) : true)) {
                   this.filteredLabelWorkflows.push(res)
                   if(this.selectedFolder) {
                    this.filteredLabelWorkflows = this.filteredLabelWorkflows.filter(wf => this.selectedFolder.workflows.includes(wf.id))
                   }
                }
              })
            }
          })
        }
        this.dataSource = new MatTableDataSource(this.filteredLabelWorkflows);
        
      }else{
        if(this.filteredNameWorkflows.length) {
          this.filteredNameWorkflows.forEach(res => {
            if(res.labels) {
              this.filteredLabels.forEach(labelfilt => {
                if(res.labels.length && res.labels.includes(labelfilt) && (this.filteredLabelWorkflows.length ? this.filteredLabelWorkflows.every(id => id.id !== res.id) : true)) {
                  this.filteredLabelWorkflows.push(res)
                }
              })
            }
          })
        }else{
          this.userWorkflows.forEach(res => {
            if(res.labels) {
              this.filteredLabels.forEach(labelfilt => {
                if(res.labels.length && res.labels.includes(labelfilt) && (this.filteredLabelWorkflows.length ? this.filteredLabelWorkflows.every(id => id.id !== res.id) : true)) {
                  this.filteredLabelWorkflows.push(res)
                }
              })
            }
          })
        }
        
        this.dataSource = new MatTableDataSource(this.filteredLabelWorkflows);
      }
    }else {
      this.isAdmin && this.isEditorMode ? this.dataSource = new MatTableDataSource(this.workflows) : this.dataSource = new MatTableDataSource(this.userWorkflows);
    }
  }

  clearAll() {
    this.isLoadingFilter = true
    this.filteredLabels = []
    Object.keys(this.labelGroup.controls).forEach(labelControl => {
      this.labelGroup.controls?.[labelControl].patchValue(false)
    })
    
    this.isLoadingFilter = false
    if(this.filter?.value) {
      this.filterAll(this.filter?.value)
    }
  }

  animationCreated(animationItem: AnimationItem): void {
  }

  selectLabels(label) {
    if( this.filteredLabels.includes(label) ) {
      let labelIndex = this.filteredLabels.findIndex(res => res == label)
      this.filteredLabels.splice(labelIndex, 1)
    }else{
      this.filteredLabels.push(label)
    }
  }

  deleteLabel(label) {
    let labelIndex = this.filteredLabels.findIndex(res => res == label)
    this.filteredLabels.splice(labelIndex, 1)
    this.labelGroup.controls[label].patchValue(false)
  }

  showJsonSource(event, workflow) {
    this.modalService.show(JsonViewerComponent, {
      initialState: {
        title: workflow.published ? workflow.published.name : workflow.draft.name,
        json: workflow,
        expanded: false
      },
      backdrop: 'static',
      class: 'modal-lg modal-dialog-centered',
      animated: false
    });
  }

  getWorkflowList() {

    this.loaderService.show()
    return this.workflowService.getAllWorkflows(true).then(workflowList => {
      this.dataLoaded = true
      this.allWorkflows = workflowList.workflowList
      this.workflows = workflowList.workflowList
      let notFoldered = workflowList.folders.find(res => res.default)
      if(notFoldered) {
        this.notFolderedWorkflows = this.workflows.filter(wf => notFoldered.workflows.includes(wf.id) )
      }
      this.folders = workflowList.folders.filter(res => !res.default)

      this.workflows = this.workflows.filter(res=> res)
      this.workflows.forEach(res => {
        if(res) {
          if (res['show']) {
            res['show'] = false
          }
          if(res.createdAt){
            res.createdAt = new Date(res.createdAt)
          }
          if(res.draft?.updatedAt){
            res.draft.updatedAt = new Date(res.draft.updatedAt)
          }
          if(res.published?.updatedAt){
            res.published.updatedAt = new Date(res.published.updatedAt)
          }
        }
        
        if(res.draft?.translations) {
          res.draft['listName'] = res.draft.defaultLanguage ? res.draft.translations[res.draft.defaultLanguage][res.draft.name] : res.draft.translations['en'][res.draft.name]
        }else {
          if(res.draft) {
            res.draft['listName'] = res.draft.name

          }
        }
        if(res.published?.translations) {
          res.published['listName'] = res.published.defaultLanguage ? res.published.translations[res.published.defaultLanguage][res.published.name] : res.published.translations['en'][res.published.name]
        }else{
          if(res.published) {
            res.published['listName'] = res.published ? res.published.name : null
          }
        }
        
      })
      this.sortByName()
      if (this.filter.value || (this.selectedFolder || this.notFolderedSelected)) {
        this.filterAll(this.filter.value.toLowerCase())
      } else {
        this.workflows = this.workflows.filter(res => (res.draft && !res.draft?.isVideoFlow) ||  (res.published && !res.published?.isVideoFlow) )
        this.dataSource = new MatTableDataSource(this.workflows)
      }
      this.sortDataSource()
      this.loaderService.hide()
    })
    .finally(() => {
      this.dataLoaded = true
      this.loaderService.hide()})
  }
  getMyWorkflowList() {
    this.loaderService.show()
    return this.workflowService.getMyWorkflows(true, true).then(workflowList => {
      this.normaluserWorkflows = workflowList
      this.userWorkflows = workflowList.workflowList
      this.userWorkflows = this.userWorkflows.filter(res=> res)
      this.userWorkflows.forEach(res => {
        if(res) {
          if (res['show']) {
            res['show'] = false
          }
          if(res.updatedAt){
            res.updatedAt = new Date(res.updatedAt)
          }

          if(res.translations) {
            res['listName'] = res.defaultLanguage ? res.translations[res.defaultLanguage][res.name] : res.translations['en'][res.name]
          }else {
            if(res) {
              res['listName'] = res.name
            }
          }
        }
        
      })
      if (this.filter.value) {
        this.filterAll(this.filter.value.toLowerCase())
      } else {
        this.dataSource = new MatTableDataSource(this.userWorkflows)
      }
      this.sortDataSource()
      this.loaderService.hide()
      workflowList.forEach(res => {
        if(res.labels?.length) {
          res.labels.forEach(label => {
            if(!this.allLabels.includes(label)) {
              this.allLabels.push(label)
              let labelControl = new UntypedFormControl (false)
              this.labelGroup.addControl(label, labelControl)
            }
          })
        }
      })
    })
    .catch(err => this.loaderService.hide())
    .finally(() => this.dataLoaded = true)
  }


  filterAll(nameFilter?) {
    let filteredData = []
    if(this.filteredLabelWorkflows.length) {
      filteredData = this.filteredLabelWorkflows.filter(res =>  (nameFilter || (this.filter?.value && this.filter.value !== ''))  && this.filterByWorkflowName(res, (nameFilter ? nameFilter : this.filter?.value)))
    }else{
      if(this.isAdmin && this.isEditorMode) {
        filteredData = this.workflows.filter(res => (nameFilter || (this.filter?.value && this.filter.value !== '')) && this.filterByWorkflowName(res, (nameFilter ? nameFilter : this.filter?.value)))
      }else{
        // filteredData = this.userWorkflows.filter(res => (nameFilter || (this.filter?.value && this.filter.value !== '')) && this.filterByWorkflowName(res, (nameFilter ? nameFilter : this.filter?.value)))
      }
    }
    if(this.selectedFolder || this.notFolderedSelected) {
      if(this.selectedFolder ) {
        if(filteredData.length > 0) {
          filteredData = filteredData.filter(res => this.selectedFolder.workflows.includes(res.id))
        }else{
          filteredData = this.workflows.filter(res => this.selectedFolder.workflows.includes(res.id))
        }
        this.selectedFolderWorkflowList = filteredData
      }
      if(this.notFolderedSelected) {
        if(filteredData.length>0) {
          let notFolderedWorkflowsCopy = JSON.parse(JSON.stringify(this.notFolderedWorkflows))
          let notFolderedWorkflowIds = notFolderedWorkflowsCopy.map(item =>item.id)
          filteredData = filteredData.filter(res => notFolderedWorkflowIds.includes(res.id))
        }else{
          if(!this.filter?.value) {
            filteredData = this.notFolderedWorkflows
          }
        }
      }
    }
    if(this.isAdmin &&this.isEditorMode) {
      filteredData = filteredData.filter(res => (res.draft && !res.draft?.isVideoFlow) ||  (res.published && !res.published?.isVideoFlow) )
    }
    this.dataSource = new MatTableDataSource(filteredData);
    return filteredData
  }

  filterByWorkflowName(workflow, nameFilter) {
    if(!this.isAdmin || (this.isAdmin && !this.isEditorMode)) {
      if(workflow && nameFilter) {
        return workflow['listName'].toLowerCase().includes(nameFilter.toLowerCase())
      }
    }else{
      if(workflow.published && nameFilter) {
        return workflow.published?.['listName'].toLowerCase().includes(nameFilter.toLowerCase())
      }
      if(workflow.draft && nameFilter) {
        return workflow.draft?.['listName'].toLowerCase().includes(nameFilter.toLowerCase())
      }
    }
    
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  deleteWorkflow(workflow){
    const initialState= {
      textMessage : this.translateService.instant('MAIN.CONTENT.DASHBOARD.TEXT-MESSAGE'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.DELETE-WORKFLOW')
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
      if (res) {
        const connectedWorkflow = this.workflows.find(workflowToConnect => {
          return workflowToConnect?.published && workflowToConnect?.published?.['subWorkflows'] && workflowToConnect?.published?.['subWorkflows'][workflow.id] || 
          workflowToConnect?.draft && workflowToConnect?.draft?.['subWorkflows'] && workflowToConnect?.draft?.['subWorkflows'][workflow.id]
        })
        if(connectedWorkflow) {
          const initialState= {
            textMessage : this.translateService.instant('MAIN.CONTENT.DASHBOARD.CONNECTED-WORKFLOW-WARNING-TEXT'),
            headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.DELETE-WORKFLOW'),
            cancelButtonText: this.translateService.instant('MAIN.CONTENT.DASHBOARD.CLOSE'),
            oneButtonAvailable: true
          };
          let alertmodalRef = this.modalService.show(AlertModalComponent, {
            initialState,
            backdrop: 'static',
            class: 'modal-dialog-centered',
            animated: false
          });
        }else{
          this.loaderService.show()
          this.workflowService.deleteWorkflow(workflow.id).then(res => {
            this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.DASHBOARD.DELETE-SUCCESSFUL'))
            this.getWorkflowList()
         })
         .catch(err => this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.DELETE-ERROR')))
        }
      }
    }))
  }

  duplicateWorkflow(workflow) {
    const initialState= {
      textMessage : this.translateService.instant('MAIN.CONTENT.DASHBOARD.ANOTHER-COPY'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.DUPLICATE-WORKFLOW'),
      confirmButtonText: this.translateService.instant('MAIN.CONTENT.DASHBOARD.CONTINUE'),
      confirmButtonStyle: 'primary'

    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
      if (res) {
        // let confirmModalRef = this.modalService.show(DefaultLanguageModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
        // this.subscriptions.push(confirmModalRef.content.onClose.subscribe((res) => {
        //   if (res == true) {
        //     confirmModalRef.hide();
        //     this.loaderService.show()
        //     this.workflowService.createWorkflow()
        //     .then(workflow => this.copyWorkflow(workflow, confirmModalRef.content.workflowName, confirmModalRef.content.defaultLanguage ))
        //     .finally(() => this.loaderService.hide())
        //   }
        // }))
        this.copyWorkflow(workflow)

      }
    }))
  }

  copyWorkflow(workflow, name?, lang?) {
    this.loaderService.show()
    this.workflowService.createWorkflow(workflow).then(res => {
      res.draft = workflow.draft
      res.lastVersion = 1
      res.createdAt = (new Date()).getTime()
      if(res.draft) {
        res.draft.version = 1
      }
      if(res.published) {
        res.published.version = 1
      }

      return res
    }).then(workflow => this.open(workflow, 'draft', 'new' ))
    .finally(() => this.loaderService.hide())

  }

  createWorkflow() {
    const initialState= {};
    let confirmModalRef = this.modalService.show(DefaultLanguageModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    this.subscriptions.push(confirmModalRef.content.onClose.subscribe((res) => {
      if (res == true) {
        let nameTranslationId = `$~#${this.dbService.createPushId()}$~#`
        let descriptionranslationId = `$~#${this.dbService.createPushId()}$~#`
        
        let workflowObject = {
          draft: 
          { 
            connectionEnabled: false,
            name: nameTranslationId,
            description: descriptionranslationId, 
            steps: {}, 
            version: 1, 
            initialStep: '', 
            labels: [], 
            dataVersion: environment.dataVersion ,
            defaultLanguage: confirmModalRef.content.defaultLanguage,
            translations : {[confirmModalRef.content.defaultLanguage]: { [nameTranslationId]: confirmModalRef.content.workflowName, [descriptionranslationId]: ''}}
          }, 
          users: [], 
          lastVersion: 1
        }
        
        let lang = languagesJson.languages.find(res => res.code == workflowObject.draft.defaultLanguage)
        this.workflowLanguageService.setCurrentLanguage({code: workflowObject.draft.defaultLanguage, name:lang.name})
        confirmModalRef.hide();
        this.loaderService.show()
        this.workflowService.createWorkflow(workflowObject)
        .then(workflow => this.open(workflow, 'draft', 'new', confirmModalRef.content.workflowName, confirmModalRef.content.defaultLanguage ))
        .catch(error => {
          if(error.error == 'permission-denied') {
            this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.WORKFLOW-NOT-FOUND'))
          }
          if(error.error == 'workflow-limit-reached') {
            this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.CREATION-LIMIT'))
          }
          if(error.error == 'internal-error') {
            this.snackBarService.error(this.translateService.instant('LOGIN.INTERNAL-ERROR'))
          }
        })
        .finally(() => this.loaderService.hide())
      }
    }))
  }

  open(workflow: Workflow, type: "published" | "draft", status?, workflowName?, defaultLanguage?): void {
    const addOns = {
      hideConnections: this.hideConnections,
      isQrAvailable: this.isQrAvailable,
      hasVideoFlow: this.hasVideoFlow,
      workflowSections: this.workflowSections
    }
    workflow['show'] = false
    if(!workflow.draft['translations'] && status == 'new') {
      const [instance, onClose] = this.detailPageService.loadComponent(WorkflowComponent, { workflow: workflow.id })
      let nameTranslationId = `$~#${this.dbService.createPushId()}$~#`
      let descriptionranslationId = `$~#${this.dbService.createPushId()}$~#`
      workflow.draft['translations'] = {[defaultLanguage]: { [nameTranslationId]: workflowName ? workflowName : 'New Workflow', [descriptionranslationId]: ''}}
      workflow.draft.defaultLanguage = workflow.draft?.defaultLanguage ? workflow.draft?.defaultLanguage : defaultLanguage
      workflow.draft.name = nameTranslationId
      workflow.draft.description = descriptionranslationId
      let lang = languagesJson.languages.find(res => res.code == defaultLanguage)
      this.workflowLanguageService.setCurrentLanguage({code: defaultLanguage, name:lang.name})

      instance.workflow = workflow
      instance.type = type
      instance.status = status
      instance.addOns = addOns
      instance.publish.subscribe(res=> {
        if(res) {
          workflow['show'] = false
          this.loaderService.show()
          this.workflowService.publishWorkflowDraft(workflow.id)
          .then(()=> this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.DASHBOARD.PUBLISH-SUCCESSFUL')))
          .catch(err => {
            this.loaderService.hide()
            this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.PUBLISH-ERROR'))})
          .finally(() => {
            this.getWorkflowList().then(()=> {
              let wf = this.workflows.find(publishedWorkflow => publishedWorkflow.id == workflow.id)
              this.openPublishedView(wf)
              this.loaderService.hide()

            })
            
          })
        }
      })
      onClose.then(() => {
        this.detailPageService.removeComponent()
        this.getWorkflowList()
        if(this.tabIndex) {
          this.onTabClicked(this.tabIndex)
        }
      })
    }else if(!workflow.draft['translations']){
      const initialState= {
        headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.SELECT-DEFAULT-LANGUAGE'),
        workflowStatus: 'update',
        confirmButtonText: this.translateService.instant('MAIN.CONTENT.DASHBOARD.UPDATE')
      };
      let confirmModalRef = this.modalService.show(DefaultLanguageModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
      this.subscriptions.push(confirmModalRef.content.onClose.subscribe((res) => {
        if (res == true) {
          confirmModalRef.hide();
          defaultLanguage = confirmModalRef.content.defaultLanguage

          const nameTranslationId = `$~#${this.dbService.createPushId()}$~#`
          const descriptionranslationId = `$~#${this.dbService.createPushId()}$~#`

          let newTranslationObject = {
            [nameTranslationId] : workflow.draft.name,
            [descriptionranslationId] : workflow.draft.description
          }
          workflow.draft.name = nameTranslationId
          workflow.draft.description = descriptionranslationId
          workflow.draft.defaultLanguage = defaultLanguage

          const stepIds = workflow[type].steps ? Object.keys(workflow[type].steps) : []
          let workflowSteps = stepIds.map(sid => {
            const stp = JSON.parse(JSON.stringify(workflow[type].steps[sid]))
            stp.id = sid
            return stp
          })
          workflowSteps.forEach(step => {
            let nameTranslationId = `$~#${this.dbService.createPushId()}$~#`
            newTranslationObject[nameTranslationId] = step.data.name
            step.data.name = nameTranslationId

            step.data?.layouts.forEach(layout => {
              if(layout.items[0].templateOptions) {
                let labelTranslationId = `$~#${this.dbService.createPushId()}$~#`
                newTranslationObject[labelTranslationId] = layout.items[0].templateOptions.label
                layout.items[0].templateOptions.label = labelTranslationId
                if(layout.items[0].templateOptions.placeholder || layout.items[0].templateOptions.placeholder == '') {
                let placeholderTranslationId = `$~#${this.dbService.createPushId()}$~#`
                newTranslationObject[placeholderTranslationId] = layout.items[0].templateOptions.placeholder
                layout.items[0].templateOptions.placeholder = placeholderTranslationId
                }
                if(layout.items[0].templateOptions.options) {
                  layout.items[0].templateOptions['translationAvailable'] = true
                  layout.items[0].templateOptions.options.forEach(option => {
                    let optionTranslationId = `$~#${this.dbService.createPushId()}$~#`
                    newTranslationObject[optionTranslationId] = option.label
                    option.label = optionTranslationId
                  })
                }
              }
            })
          })
          workflow.draft.steps = workflowSteps.reduce((steps, stp) => {
            steps[stp.id] = { data: stp.data, coordinates: stp.coordinates }
            return steps
          }, {})
          workflow.draft['translations'] = {[defaultLanguage]: newTranslationObject }
          let isChangeAvailable = true
          const [instance, onClose] = this.detailPageService.loadComponent(WorkflowComponent, { workflow: workflow.id, isChangeAvailable: isChangeAvailable })
          let lang = languagesJson.languages.find(res => res.code == defaultLanguage)

          this.workflowLanguageService.setCurrentLanguage({code: defaultLanguage, name:lang.name})
          instance.workflow = workflow
          instance.type = type
          instance.status = status
          instance.isChangeAvailable = isChangeAvailable
          instance.publish.subscribe(res=> {
            if(res) {
              this.workflowService.publishWorkflowDraft(workflow.id)
              .then(()=> this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.DASHBOARD.PUBLISH-SUCCESSFUL')))
              .catch(err => this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.PUBLISH-ERROR')))
              .finally(() => {
                this.loaderService.hide()
                this.getWorkflowList().then(()=> {
                  let wf = this.workflows.find(publishedWorkflow => publishedWorkflow.id == workflow.id)
                  this.openPublishedView(wf)
                })
              })
            }
          })
          onClose.then(() => {
            this.detailPageService.removeComponent()
              this.getWorkflowList()
          })
        }
      }))
    }else{
      if(type == 'draft' && workflow.draft?.defaultLanguage) {
        this.layoutPlaceholderControl(workflow,type)
      }
      const [instance, onClose] = this.detailPageService.loadComponent(WorkflowComponent, { workflow: workflow.id })
          instance.workflow = workflow
          instance.type = type
          instance.status = status
          instance.addOns = addOns

          instance.publish.subscribe(res=> {
            if(res) {
              this.workflowService.publishWorkflowDraft(workflow.id)
              .then(()=> {
                this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.DASHBOARD.PUBLISH-SUCCESSFUL'))
              })
              .catch(err => this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.PUBLISH-ERROR')))
              .finally(() => {
                this.loaderService.hide()
                this.getWorkflowList().then(()=> {
                  let wf = this.workflows.find(publishedWorkflow => publishedWorkflow.id == workflow.id)
                  this.openPublishedView(wf)
                })
              })
            }
          })
          onClose.then(() => {
            this.detailPageService.removeComponent()
              this.getWorkflowList()
              if(this.tabIndex) {
                this.onTabClicked(this.tabIndex)
              }
          })

    }
  }

  createWorkflowWithOpenAI() {
    const initialState= {
    };
    let confirmModalRef = this.modalService.show(OpenAiModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
    this.subscriptions.push(confirmModalRef.content.onClose.subscribe((res) => {
      if (res == true) {
        this.showLoadingAI = true
        confirmModalRef.hide();
        this.workflowService.workflowGenerate(confirmModalRef.content.prompt).then(response => {
          if(response) {
            this.open(response, 'draft')
          }
        })
        .catch(err => {
          if(err.error.message == 'invalid-workflow-error') {
            this.snackBarService.error(this.translateService.instant('MODALS.GENERATE-WORKFLOW.INVALID-WORKFLOW-ERROR'))
          }
          if(err.error.message == 'permission-denied') {
            this.snackBarService.error(this.translateService.instant('MODALS.GENERATE-WORKFLOW.PERMISSION-DENIED'))
          }
          if(err.error.message == 'prompt-required') {
            this.snackBarService.error(this.translateService.instant('MODALS.GENERATE-WORKFLOW.PROMPT-REQUIRED'))
          }
          if(err.error.message == 'internal-error') {
            this.snackBarService.error(this.translateService.instant('MODALS.GENERATE-WORKFLOW.INTERNAL-ERROR'))
          }
        })
        .finally(() => {
          this.loaderService.hide()
          this.showLoadingAI = false
        })
      }
    }))
  }

  layoutPlaceholderControl(workflow,type) {
    const stepIds = workflow[type].steps ? Object.keys(workflow[type].steps) : []
    let workflowSteps = stepIds.map(sid => {
      const stp = JSON.parse(JSON.stringify(workflow[type].steps[sid]))
      stp.id = sid
      return stp
    })
    workflowSteps.forEach(step => {
      step.data?.layouts.forEach(layout => {
        if(layout.items[0].templateOptions) {
          if((layout.items[0].templateOptions.placeholder && layout.items[0].templateOptions.placeholder == '') || layout.items[0].templateOptions.placeholder == '') {
          let placeholderTranslationId = `$~#${this.dbService.createPushId()}$~#`
          Object.keys(workflow.draft.translations).forEach(translationKey => {
            workflow.draft.translations[translationKey][placeholderTranslationId] = layout.items[0].templateOptions.placeholder
          })
          layout.items[0].templateOptions.placeholder = placeholderTranslationId
          }
        }
      })
    })

    workflow.draft.steps = workflowSteps.reduce((steps, stp) => {
      steps[stp.id] = { data: stp.data, coordinates: stp.coordinates }
      return steps
    }, {})
  }

  openPublishedView(workflow:Workflow) {
    if(!workflow.published.defaultLanguage) {
      const initialState= {
        textMessage: this.translateService.instant(`MAIN.CONTENT.DASHBOARD.NEW-VERSION`),
        confirmButtonText: this.translateService.instant(`MAIN.CONTENT.WORKFLOW.TEXT-MESSAGE.OK`),
        confirmButtonStyle: 'primary',
        instantClose:false,
      };
      let confirmModalRef = this.modalService.show(AlertModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
      this.subscriptions.push(confirmModalRef.content.onClose.subscribe((res) => {
        if (res == true) {
          confirmModalRef.hide();
        }
      }))
    }else{
      let saveStatus = false
      workflow['show'] = false
      const [instance, onClose] = this.detailPageService.loadComponent(WorkflowComponent, { workflow: workflow.id })
      instance.workflow = workflow
      instance.type = 'published'
      instance.saveStatus = saveStatus
      instance.easyAccessUpdated = this.easyAccessUpdated
  
      onClose.then(()=>{
        this.detailPageService.removeComponent()
        if(instance.easyAccessUpdated) {
          this.getWorkflowList()
        }
        if(instance.saveStatus){
          if(workflow.draft){
            this.open(workflow,'draft')
          }else{
            this.createDraft(workflow)
          }
        }
      })
    }
    
  }

  createDraft(workflow: Workflow) {
    workflow['show'] = false
    this.loaderService.show()
    this.workflowService.createWorkflowDraft(workflow.id)
    .then(w => this.open(w, "draft"))
    .finally(() => this.loaderService.hide())
  }

  publishWorkflow(workflow: any) {
    workflow['show'] = false
    this.loaderService.show()
    this.workflowService.publishWorkflowDraft(workflow.id)
    .then(()=> this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.DASHBOARD.PUBLISH-SUCCESSFUL')))
    .catch(err => this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.PUBLISH-ERROR')))
    .finally(() => {
      this.loaderService.hide()
      this.getWorkflowList()
    })
  }

  dropdownAction(workflow, event: Event) {
    event.stopPropagation()
    this.workflows.forEach(res => {
      res['show'] = false
    })
    workflow['show'] = !workflow['show']
  }

  openReport(workflow){
    this.router.navigate(['/report'],{state: { workflow:workflow}})
    // window.open('/report', "_blank");
    //this.router.navigate([],{state: { workflow:workflow}}).then(result => {  window.open('/report', '_blank'); });
  }

  showWorkflowUsers(workflow) {
    let usersModalRef = this.modalService.show(WorkflowUsersModalComponent, {
      backdrop: 'static',
      class: 'modal-lg modal-dialog-centered',
      animated: false,
      initialState: {
        workflow: workflow
      }
    })
     usersModalRef.content.onClose.subscribe(res => {
      if(res) {
        workflow.users = res.map(u => u.id)
        usersModalRef.hide()
      }
     })
  }

  selectWorkflow(workflow){
    this.loaderService.show()
    if(!workflow.draft){
      this.workflowService.createWorkflowDraft(workflow.id).then((workflowWithDraftVersion)=> {
        this.newStepListData(workflowWithDraftVersion)
      })
    }else{
      this.newStepListData(workflow)
    }
  }
  newStepListData(workflow){
    const stepIds = workflow.draft?.steps ? Object.keys(workflow.draft?.steps) : []
    const workflowSteps = stepIds.map(sid => {
      const stp = JSON.parse(JSON.stringify(workflow.draft?.steps[sid]))
      stp.id = sid
      return stp
    }).sort((a,b) => a.data.order - b.data.order)
    if(workflowSteps.length == 0){
      workflow['draft'].initialStep = this.stepData.id
    }
    this.stepData.data.order = workflowSteps.length
    workflowSteps.length !== 0 ? workflowSteps[workflowSteps.length-1].data['next'] = this.stepData.id : null
    this.stepData['next'] = null
    workflowSteps.push(this.stepData)
    workflow.draft.steps = workflowSteps
    this.workflowSelected.emit(workflow);
  }

  exportPublishedWorkflows() {
    this.dbService.exportPublishedWorkflows()
    .then(errorCode => {
      if (errorCode) {
        if (errorCode === 'choose-canceled') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.EXPORT-CANCELED'))
        } else if (errorCode === 'export-folder-exists') {
          this.snackBarService.error(this.translateService.instant('MAIN.CONTENT.DASHBOARD.EXPORT-FOLDER'))
        } else if (errorCode === 'unknown-error') {
          this.snackBarService.error(this.translateService.instant('MODALS.GENERATE-WORKFLOW.INTERNAL-ERROR'))
        }
      } else {
        this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.DASHBOARD.EXPORT-SUCCESSFUL'))
      }
    })
  }

  getUsersName(uid) {
    const user = this.allUsers.find(u => u.id === uid)
    if (user) {
      return user.name
    } else {
      return ""
    }
  }

  onTabClicked(index) {
    this.filteredLabelWorkflows = []
    this.filteredLabels = []
    this.notFolderedSelected = false
    this.tabIndex = index
    if(index == 1 && this.folders?.length) {
      this.selectFolder(this.folders[0])
    }
    if(index == 0) {
      this.selectedWorkflowIds = []
      this.selectedFolder = null
      this.notFolderedSelected = false
      this.getWorkflowList()
    }
  }

  createNewFolder() {
    const initialState= {
      folders : this.folders
    };
    let folderModalRef = this.modalService.show(FolderInfoModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    this.subscriptions.push(folderModalRef.content.onClose.subscribe((res) => {
      if(res) {
        this.folders.push({name: res, workflows: []})
        this.setWorkflowFolders()
      }
    }))
  }

  editFolder(folder) {
    const initialState= {
      folder: folder.name,
      folders : this.folders
    };
    let folderModalRef = this.modalService.show(FolderInfoModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    this.subscriptions.push(folderModalRef.content.onClose.subscribe((res) => {
      if(res) {
        const editedFolderIndex = this.folders.findIndex(item => item.name == folder.name)
        if(editedFolderIndex || editedFolderIndex == 0 ) {
          this.folders[editedFolderIndex].name = res
          this.setWorkflowFolders()
        }
      }
    }))
  }

  moveToFolder(itemsToBeMoved) {
    const initialState= {
      folders: this.folders,
      selectedFolderFromDashboard: this.selectedFolder,
      tabIndex: this.tabIndex,
      workflowId: itemsToBeMoved && !Array.isArray(itemsToBeMoved) ? itemsToBeMoved : null
    };
    let folderModalRef = this.modalService.show(FolderListModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    this.subscriptions.push(folderModalRef.content.onClose.subscribe((res) => {
    }))
    this.subscriptions.push(folderModalRef.content.onSelectFolder.subscribe(res => {
      this.loaderService.show()
      if(res) {
        if(itemsToBeMoved && !Array.isArray(itemsToBeMoved)) {
          this.selectedWorkflowIds.push(itemsToBeMoved)
          itemsToBeMoved = [itemsToBeMoved]
        }
        this.moveWorkflowItemToFolder(res, itemsToBeMoved)
        this.setWorkflowFolders()
        this.selectedWorkflowIds = []
      }else{
        this.loaderService.hide()
      }
    }))
  }

  moveWorkflowItemToFolder(newFolder, itemsToBeMoved) {
    let folderItem = this.folders.find(folderItem => folderItem.name == newFolder )
    let selectedFolder = this.folders.find(folder => this.selectedFolder && folder.name == this.selectedFolder.name)
    if(this.tabIndex == 0) {
      let workflowToBeMoved = itemsToBeMoved[0]
      let workflowFolder = this.folders.find(folder => folder.workflows && folder.workflows.includes(workflowToBeMoved))
      if(workflowFolder) {
        workflowFolder.workflows = workflowFolder.workflows.filter(id => id !== workflowToBeMoved)
      }else{
        this.notFolderedWorkflows = this.notFolderedWorkflows.filter(wf => wf.id !== workflowToBeMoved)
      }
    }else{
      if(selectedFolder) {
        selectedFolder.workflows = selectedFolder.workflows.filter(id => !this.selectedWorkflowIds.includes(id))
      }else{
        this.notFolderedWorkflows = this.notFolderedWorkflows.filter(wf => !this.selectedWorkflowIds.includes(wf.id))
        this.dataSource = new MatTableDataSource(this.notFolderedWorkflows)
      }
    }
    
    this.selectedWorkflowIds.forEach(resp => {
      let wf = this.workflows.find(wf => wf.id == resp)
      wf['isSelected'] = false
      
    })
    this.dataSource.sort = this.sort;
    folderItem.workflows = folderItem.workflows.concat(...itemsToBeMoved)
  }

  selectWorkflowToMove(workflow) {
    if(this.selectedWorkflowIds.length && this.selectedWorkflowIds.includes(workflow.id)) {
      const selectedWorkflowIndex = this.selectedWorkflowIds.findIndex(wf => wf.id == workflow.id)
      if(selectedWorkflowIndex) {
        this.selectedWorkflowIds.splice(selectedWorkflowIndex, 1)
        workflow['isSelected'] = false
      }
    }else{
      this.selectedWorkflowIds.push(workflow.id)
      workflow['isSelected'] = true
    }
  }

  deleteFolder(folder) {
    const initialState= {
      textMessage : this.translateService.instant('MAIN.CONTENT.DASHBOARD.FOLDER.DELETE-FOLDER-WARNING-TEXT'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.DASHBOARD.FOLDER.DELETE-FOLDER'),
      regularTextMessage : this.translateService.instant('MAIN.CONTENT.DASHBOARD.FOLDER.DELETE-FOLDER-INFO-TEXT')
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });

    this.subscriptions.push(alertmodalRef.content.onClose.subscribe((res) => {
      if(res) {
        const deletedFolderIndex = this.folders.findIndex(item => item.name == folder.name)
        if(deletedFolderIndex || deletedFolderIndex == 0 ) {
          let deletedFolderWorkflows = this.workflows.filter(res => this.folders[deletedFolderIndex].workflows.includes(res.id))
          this.notFolderedWorkflows = this.notFolderedWorkflows.concat(deletedFolderWorkflows)
          this.folders.splice(deletedFolderIndex, 1)
          this.setWorkflowFolders()
          if(this.folders?.length) {
            this.selectFolder(this.folders[0])
          }else{
            this.showNotFolderedWorkflows()
          }
        }
      }
    }))

  }

  folderDelete(event) {
    let deletedFolderWorkflows = this.workflows.filter(res => this.folders[event].workflows.includes(res.id))
    this.notFolderedWorkflows = this.notFolderedWorkflows.concat(deletedFolderWorkflows)
    if(this.folders?.length) {
      this.selectFolder(this.folders[0])
    }else{
      this.showNotFolderedWorkflows()
    }
  }

  selectedFolderChanged(event) {
    this.selectedFolder = event
    this.notFolderedSelected = false
    this.selectedFolderWorkflowList = this.filterAll()
    this.dataSource = new MatTableDataSource(this.filterAll())

    this.unselectWorkflows()

  }

  unselectWorkflows() {
    this.selectedWorkflowIds.forEach(resp => {
      let wf = this.workflows.find(wf => wf.id == resp)
      wf['isSelected'] = false
      
    })
    this.selectedWorkflowIds = []
  }

  selectFolder(folder) {
    this.selectedFolder = folder
    this.notFolderedSelected = false
    this.selectedFolderWorkflowList = this.filterAll()
    this.dataSource = new MatTableDataSource(this.filterAll())

    this.unselectWorkflows()
  }

  showNotFolderedWorkflows() {
    this.selectedFolder = null
    this.notFolderedSelected = true
    this.unselectWorkflows()

    const filteredWorkflows = this.notFolderedWorkflows
    this.selectedFolderWorkflowList = this.filterAll()
    filteredWorkflows.sort(function(a, b) {
      if(a?.draft?.['listName'] && b?.draft?.['listName']){
        if(a.draft['listName'].toLowerCase() < b.draft['listName'].toLowerCase()) {return -1}
        else if(a.draft['listName'].toLowerCase() > b.draft['listName'].toLowerCase()){return 1}
      }
      if(a?.published?.['listName'] && b?.published?.['listName']){
        if(a.published['listName'].toLowerCase() < b.published['listName'].toLowerCase()) {return -1}
        else if(a.published['listName'].toLowerCase() > b.published['listName'].toLowerCase()){return 1}
      }
      if(a?.published?.['listName'] && b?.draft?.['listName']){
        if(a.published['listName'].toLowerCase() < b.draft['listName'].toLowerCase()) {return -1}
        else if(a.published['listName'].toLowerCase() > b.draft['listName'].toLowerCase()){return 1}
      }
      if(a?.draft?.['listName'] && b?.published?.['listName']){
        if(a.draft['listName'].toLowerCase() < b.published['listName'].toLowerCase()) {return -1}
        else if(a.draft['listName'].toLowerCase() > b.published['listName'].toLowerCase()){return 1}
      }
      return 0;
    })
    this.dataSource = new MatTableDataSource(this.filterAll())
    this.sortByName()
    this.sortDataSource()
  }

  notFolderedSelectedChange(event) {
    if(event) {
      this.showNotFolderedWorkflows()
    }
  }

  setWorkflowFolders() {
    const workflowFolders = this.folders
    this.workflowService.setWorkflowFolders(workflowFolders).then(res=> {
      if(this.selectedFolder) {
        this.selectFolder(this.selectedFolder)
      }
    })
    .catch()
    .finally(()=> this.loaderService.hide())
  }

  sortByName() {
    this.workflows.sort(function(a, b) {
      if(a?.draft?.['listName'] && b?.draft?.['listName']){
        if(a.draft['listName'].toLowerCase() < b.draft['listName'].toLowerCase()) {return -1}
        else if(a.draft['listName'].toLowerCase() > b.draft['listName'].toLowerCase()){return 1}
      }
      if(a?.published?.['listName'] && b?.published?.['listName']){
        if(a.published['listName'].toLowerCase() < b.published['listName'].toLowerCase()) {return -1}
        else if(a.published['listName'].toLowerCase() > b.published['listName'].toLowerCase()){return 1}
      }
      if(a?.published?.['listName'] && b?.draft?.['listName']){
        if(a.published['listName'].toLowerCase() < b.draft['listName'].toLowerCase()) {return -1}
        else if(a.published['listName'].toLowerCase() > b.draft['listName'].toLowerCase()){return 1}
      }
      if(a?.draft?.['listName'] && b?.published?.['listName']){
        if(a.draft['listName'].toLowerCase() < b.published['listName'].toLowerCase()) {return -1}
        else if(a.draft['listName'].toLowerCase() > b.published['listName'].toLowerCase()){return 1}
      }
      return 0;
    })
  }

  sortDataSource() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "draft['listName']":
          if (item.draft) {
            return item.draft['listName']
          }
          return item['listName']
        default:
          return item[property]
      }
    };
    this.dataSource.sort = this.sort;
  }

}
