import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DetailPageService } from 'src/app/services/detail-page.service';
import { NavigationService } from 'src/app/services/support/navigation.service';
import { Router } from '@angular/router';
import { AppService } from '@services/support/app.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  @ViewChild("detailContainer", { read: ViewContainerRef, static: true }) detailContainer!: ViewContainerRef

  isAuthenticated = false
  authSub: Subscription

  sidebarSub: Subscription
  sidebarClosed: boolean = true;
  isAdmin: boolean = true

  userWfIsOpen = false
  reportIsOpen = false
  isEditorMode = false

  webhooksAvailable: boolean = false
  hasVideoFlow: boolean = false
  addOns
  browser
  isMobile : boolean = false

  constructor(
    private authService: AuthService,
    private detailPageService: DetailPageService,
    private navigationService: NavigationService,
    private router: Router,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.authService.getAuth().subscribe(auth => {
      if (!auth) {
        this.router.navigate(['/login']);
      }
    });

    this.authService.getAccountData().then(account => {
      this.webhooksAvailable = account.data.accountData.add_ons?.workflowwebhooks
      this.hasVideoFlow = account.data.accountData.add_ons?.videoflow
      this.addOns = {
        webhooksAvailable : this.webhooksAvailable,
        hasVideoFlow : this.hasVideoFlow
      }
    })
    
    this.authSub = this.authService.user.subscribe(user => {
      this.isAuthenticated = !!user;
      this.isAdmin = user.role === 'admin' || user.role === 'coadmin' || user.coadmin || user.editorAccess
    })
    this.detailPageService.setDetailContainer(this.detailContainer);
    this.navigationService.sidebarState.subscribe(state => {
      this.sidebarClosed = state;
    });

    this.appService.userWorkflowisOpen.subscribe(res => {
        this.userWfIsOpen = res
    })
    this.appService.reportisOpen.subscribe(res => {
      this.reportIsOpen = res
    })
    this.appService.isEditorMode.subscribe(res => {
      this.isEditorMode = res
      sessionStorage.setItem('isEditorMode', res.toString())
    })
  }

  ngOnDestroy(): void {
    if (this.authSub) { this.authSub.unsubscribe() }
    if (this.sidebarSub) { this.sidebarSub.unsubscribe() }
  }

}
