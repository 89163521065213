import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { DetailPageService } from '@services/detail-page.service';
import { LoaderService } from '@services/support/loader.service';
import { SnackbarService } from '@services/support/snackbar.service';
import { UserService } from '@services/user.service';
import { WorkflowService } from '@services/workflow.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime, Subscription } from 'rxjs';
import { AlertModalComponent } from 'src/app/components/modals/alert-modal/alert-modal.component';
import { User } from '../../../../models/User'
import { UserDetailComponent } from '../user-detail/user-detail.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {

  @ViewChild('optionsDropdown') optionsDropdown: any;
  @ViewChild('menu') menu: any;
  @ViewChild(MatSort, { static: false })
  set sort(sorter: MatSort) {
    this.dataSource.sort = sorter;
  }

  dataSource = new MatTableDataSource<any>()
  displayedColumns: string[] = [
    "workflowUser",
    "name",
    "username",
    // "teams",
    "actions"
  ];
  displayedTeamColumns: string[] = [
    "teamname",
    "members",
    "actions"
  ];
  filter = new UntypedFormControl()

  currentUser: User = null;

  userList: User[] = []
  workflowUserLimit : number

  subscriptions: Subscription[] = []

  dataLoaded = false;
  showCountPanel = false

  workflowUserCount = 0
  newDeactivatedWorkflows = 0;
  newActivatedWorkflows = 0;
  activatedUsers = []
  deactivatedUsers = []
  total

  formGroup = new UntypedFormGroup({});
  tabIndex = 0

  userDetailOpen: boolean = false;
  
  constructor( 
    private workflowService: WorkflowService,
    private userService: UserService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private snackBarService: SnackbarService,
    private modalService: BsModalService,
    private detailPageService: DetailPageService,
    private translateService: TranslateService) {}

  ngOnInit(): void {
    this.getAllUsers()

    this.formGroup.valueChanges.pipe().subscribe(res => {
      this.showCountPanel = true
    })
    if(this.filter.value){
      this.filterUsers(this.filter.value.toLowerCase());
    }else{
      this.dataSource = new MatTableDataSource(this.userList)
    }
    this.filter.valueChanges.pipe(debounceTime(300)).subscribe(res => {
      if (res) {
        this.filterUsers(res.toLowerCase());
      } else {
        this.dataSource = new MatTableDataSource(this.userList)
      }
    })
    this.currentUser = this.authService.currentUser;
  }

  getAllUsers(){
    this.loaderService.show()
    this.workflowService.getAllUsersForWorkflow().then(res => {
      this.workflowUserCount = res.workflowUserCount
      this.workflowUserLimit = res.workflowUserLimit

      this.userList = res.users.filter(user => user.auth && !user.auth.deleted)
      this.dataSource = new MatTableDataSource(this.userList)
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case "workflowUser":
            return this.formGroup.controls[item.id]?.value
          default:
            return item[property]
        }
      };
      this.dataSource.sort = this.sort;

      this.userList.forEach(user => {
        if(user.workflow_user) {
          this.activatedUsers.push(user.id)
        }else{
          this.deactivatedUsers.push(user.id)
        }
        const hasWorkflow = user.workflow_user
        const userFormControl = new UntypedFormControl (hasWorkflow)

        userFormControl.valueChanges.subscribe((newValue) => {
          const userHasWorkflow = this.userList.find((item) => item.id === user.id && item.workflow_user)
          if(!userHasWorkflow && newValue) {
            this.newActivatedWorkflows += 1
          }
          else if(!userHasWorkflow && !newValue){
            this.newActivatedWorkflows -= 1
          }
          else if(userHasWorkflow && !newValue) {
            this.newDeactivatedWorkflows +=1
          }else if(userHasWorkflow && newValue){
            this.newDeactivatedWorkflows -=1
          }
          this.total = this.newActivatedWorkflows > this.newDeactivatedWorkflows ? this.newActivatedWorkflows - this.newDeactivatedWorkflows : 0
        })
        this.formGroup.addControl(user.id, userFormControl)
      });

      this.dataLoaded = true
      this.loaderService.hide()
    })
  }

  saveWorkflowUsers(): void {
    this.loaderService.show()
    const insertedUsers = this.userList.filter((user) => !this.hasWorkflow(user) && this.formGroup.value[user.id]).map((user) => user.id)
    const disabledWorkflowUsers = this.userList.filter((user) => this.hasWorkflow(user) && !this.formGroup.value[user.id]).map((user) => user.id)
    this.workflowService.saveDisabledUsers(insertedUsers, disabledWorkflowUsers).then(res => {
      this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.USER-LIST.SAVE-SUCCESSFUL'))
      this.showCountPanel = false
      this.newDeactivatedWorkflows = 0
      this.newActivatedWorkflows = 0

      this.userList.forEach((user) => {
        if (disabledWorkflowUsers.includes(user.id)) {
          delete user.workflows
          delete user.workflow_user
        }
        if (insertedUsers.includes(user.id)) {
          user.workflow_user = true;
        }
      })
      this.showCountPanel = false
      this.newDeactivatedWorkflows = 0
      this.workflowUserCount = this.workflowUserCount - disabledWorkflowUsers.length + insertedUsers.length
    })
    .catch(err => {
      if(err.error == 'no-workflow-user-limit') {
        this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.USER-LIST.LIMIT-EXCEED'))
      }else{
        this.snackBarService.success(this.translateService.instant('MAIN.CONTENT.USER-LIST.ERROR'))
      }
    })
    .finally(() => this.loaderService.hide())
  }

  cancel(): void {
    const disabledWorkflowUsers = this.userList.filter((user) => this.hasWorkflow(user) && !this.formGroup.value[user.id]).map((user) => user.id)
    const enabledWorkflowUsers = this.userList.filter((user) => !this.hasWorkflow(user) && this.formGroup.value[user.id]).map((user) => user.id)

    disabledWorkflowUsers.forEach(id => {
      this.formGroup.controls[id].patchValue(true) 
    });
    enabledWorkflowUsers.forEach(id => {
      this.formGroup.controls[id].patchValue(false) 
    });
    
    this.showCountPanel = false
    this.newDeactivatedWorkflows = 0
    this.newActivatedWorkflows = 0
  }

  private hasWorkflow(user) {
    return user.workflow_user;
  }

  private filterUsers(filterData?) {
    let filteredData =[]
    filteredData = this.userList.filter(user => {
      if(user){
        return user.name.toLowerCase().includes(filterData.toLowerCase())
      }
      else{
       return filteredData
      }
       })
    this.dataSource = new MatTableDataSource(filteredData);
  }

  onDelete(userId: string) {
    const initialState = {
      textMessage : this.translateService.instant('MAIN.CONTENT.USER-LIST.DELETE-OR-NOT'),
      confirmButtonText : this.translateService.instant('MAIN.CONTENT.USER-LIST.ACTIONS.DELETE'),
      headerTitle: this.translateService.instant('MAIN.CONTENT.WORKFLOW.HEADER-TITLES.DELETE-USER')
    };
    let alertmodalRef = this.modalService.show(AlertModalComponent, {
      initialState,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      animated: false
    });
    alertmodalRef.content.onClose.subscribe(res => {
      if (res) {
        this.loaderService.show();
        this.userService.deleteUser(userId).finally(() => {
          this.getAllUsers();
          this.loaderService.hide();
        });
      } else {
        alertmodalRef.hide();
      }
    })
  }

  onEdit(user): void {
    this.userDetailOpen = true;
    const [instance, onClose] = this.detailPageService.loadComponent(UserDetailComponent)
    instance.user = user;
    instance.detailMode = 'edit';
    onClose.then(() => {
      this.userDetailOpen = false;
      this.getAllUsers();
    })
  }

  onCreate(): void {
    this.userDetailOpen = true;
    const [instance, onClose] = this.detailPageService.loadComponent(UserDetailComponent)
    instance.detailMode = 'create';
    instance.workflowUserLimit = this.workflowUserLimit;
    instance.workflowUserCount = this.workflowUserCount;
    onClose.then(() => {
      this.userDetailOpen = false;
      this.getAllUsers();
    })
  }

  openUserDetail(user): void {
    this.userDetailOpen = true;
    const [instance, onClose] = this.detailPageService.loadComponent(UserDetailComponent)
    instance.user = user;
    onClose.then(() => {
      this.userDetailOpen = false;
      this.getAllUsers();
    })
  }

  ngOnDestroy(): void {
    this.detailPageService.removeComponent();
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  onTabClicked(index: number): void {
    this.tabIndex = index;
    this.dataLoaded = false;

    if (index === 0) {
      this.displayedColumns = [
        "workflowUser",
        "name",
        "username",
        // "teams",
        "actions"
      ];
    } else if (index === 1) {
      this.displayedColumns = [
        "teamname",
        "members",
        "actions"
      ];
      this.getAllTeams(); 
    }

    this.dataLoaded = true;
  }

  getAllTeams() {
    // henüz yok
  }


}
